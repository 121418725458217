<template>
  <div>
    <div class="flex items-center mb-4">
      <SelectDate
        v-model:year="selectedDate.year"
        v-model:month="selectedDate.month"
        :existsDates="existsDates"
      />
    </div>

    <template v-if="isManager">
      <Card :style="cardWidth">
        <template #title>
          <div class="flex justify-between items-center">
            <span>
              {{ selectedDate.year }}年{{ selectedDate.month }}月の労務管理
            </span>
            <BasicButton @click="goto({ name: 'UsersLaborManagementsYear' })">
              年間労務管理
            </BasicButton>
          </div>
        </template>
        <template #content>
          <div v-if="selectedLaborType" class="overflow-scroll w-100">
            <DataTable :value="organizationMembers" editMode="cell" scrollable>
              <Column
                :field="(m) => m.user.lastName + m.user.firstName"
                header="名前"
                style="min-width: 120px"
              ></Column>
              <Column
                header="所定労働日数"
                field="laborDays"
                style="min-width: 100px"
              >
                <template #body="{ data }">
                  {{ memberLaborInformationsMap[data.id].laborDays }}
                  <i class="pi pi-pencil !text-xs"></i>
                </template>
                <template #editor="{ data, field }">
                  <InputNumber
                    :model-value="memberLaborInformationsMap[data.id].laborDays"
                    autofocus
                    class="w-fit"
                    input-class="!py-0 !text-xs"
                    @update:model-value="
                      updateLaborInfo($event, field, data.id)
                    "
                  />
                </template>
              </Column>
              <Column header="公休日数" style="min-width: 100px">
                <template #body="slotProps">
                  {{
                    memberLaborInformationsMap[slotProps.data.id]
                      .publicHolidayDays
                  }}
                </template>
              </Column>
              <Column header="勤務予定日数" style="min-width: 100px">
                <template #body="slotProps">
                  {{ slotProps.data.shifts.length || 0 }}
                </template>
              </Column>
              <Column header="休日勤務予定日数" style="min-width: 100px">
                <template #body="slotProps">
                  {{
                    memberLaborInformationsMap[slotProps.data.id]
                      .holidayWorkDays
                  }}
                </template>
              </Column>
              <Column
                header="特別休暇日数"
                field="specialHolidayDays"
                style="min-width: 100px"
              >
                <template #body="slotProps">
                  {{
                    memberLaborInformationsMap[slotProps.data.id]
                      .specialHolidayDays
                  }}
                  <i class="pi pi-pencil !text-xs"></i>
                </template>
                <template #editor="{ data, field }">
                  <InputNumber
                    :model-value="
                      memberLaborInformationsMap[data.id].specialHolidayDays
                    "
                    autofocus
                    input-class="!py-0 !text-xs"
                    @update:model-value="
                      updateLaborInfo($event, field, data.id)
                    "
                  />
                </template>
              </Column>
              <Column header="有休日数" style="min-width: 100px">
                <template #body="slotProps">
                  {{
                    memberLaborInformationsMap[slotProps.data.id]
                      .paidHolidayDays
                  }}
                </template>
              </Column>
              <Column header="有休残日数" style="min-width: 80px">
                <template #body="{ data }">
                  {{ memberLaborInformationsMap[data.id].leastPaidHolidayDays }}
                </template>
              </Column>
              <Column header="次の有休獲得日" style="min-width: 200px">
                <template #body="{ data }">
                  <p
                    v-if="
                      memberLaborInformationsMap[data.id]
                        .nextGetPaidHolidayDaysAt
                    "
                  >
                    {{
                      basicFormatter(
                        memberLaborInformationsMap[data.id]
                          .nextGetPaidHolidayDaysAt,
                        "withoutDateDay"
                      )
                    }}({{
                      basicFormatter(
                        memberLaborInformationsMap[data.id]
                          .nextGetPaidHolidayDaysAt,
                        "japanize"
                      )
                    }})
                  </p>
                  <p v-else-if="!data.joiningOn" class="text-red-500">
                    入社日が未設定です
                  </p>
                </template>
              </Column>
              <Column header="入社日" style="min-width: 200px">
                <template #body="{ data }">
                  <p v-if="data.joiningOn">
                    {{ basicFormatter(data.joiningOn, "withoutDateDay") }}({{
                      basicFormatter(data.joiningOn, "japanize")
                    }})
                  </p>
                  <p v-else class="text-red-500">-</p>
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
      </Card>
    </template>
  </div>
</template>

<script setup lang="ts">
// import { Container, Draggable } from "vue-smooth-dnd";
import { ref, computed, reactive, inject, Ref } from "vue";
import { luxonNow, existsDates, basicFormatter } from "/@/modules/luxon";
import {
  useRouterUtil,
  useOrganizationMember,
  useMqUtils,
} from "/@/vue/composables";
import { BasicButton, SelectDate } from "/@/vue/components/Atom";
import { DateMap, OrganizationClient } from "/@/types";
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputNumber from "primevue/inputnumber";
import { errorHandle } from "/@/modules/error";

const { goto } = useRouterUtil();

const { cardWidth } = useMqUtils();

const selectedLaborType = ref<string>("member");

// date

const today = luxonNow();

const selectedDate = reactive<DateMap>({
  year: today.year,
  month: today.month,
});

// organization

const selectedOrganizationId = inject("selectedOrganizationId");
const selectedOrganization = inject<Ref<OrganizationClient>>(
  "selectedOrganization"
);

const isManager = computed(() => {
  if (!selectedOrganization.value) return false;
  return selectedOrganization.value.isManager;
});

const { getOrganizationMembers } = useOrganizationMember();
const { data: organizationMembers, mutate: getOrganizationMembersMutate } =
  getOrganizationMembers(
    selectedOrganizationId,
    {
      withShifts: true.toString(),
      withLaborInformations: true.toString(),
    },
    selectedDate
  );

// labor informations

const memberLaborInformationsMap = computed(() => {
  if (!organizationMembers.value) return {};

  let m = {};

  organizationMembers.value.forEach((om) => {
    m[om.id] = om.laborInformations[0];
  });

  return m;
});

const { updateLaborInfo: updateLaborInfoApi } = useOrganizationMember();

async function updateLaborInfo(
  e: any,
  field: string,
  organizationMemberId: number
) {
  try {
    if (
      await updateLaborInfoApi(organizationMemberId, selectedDate, field, e)
    ) {
      getOrganizationMembersMutate();
    } else {
      console.error("updateLaborInfo failed");
    }
  } catch (e) {
    errorHandle(e);
    return;
  }
}
</script>

<style scoped></style>
