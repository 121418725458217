import Index from "./Index.vue";
import Year from "./Year.vue";

export default [
  {
    path: "",
    name: "UsersLaborManagementsIndex",
    component: Index,
    meta: {
      title: "月間労務管理",
    },
  },
  {
    path: "year",
    name: "UsersLaborManagementsYear",
    component: Year,
    meta: {
      title: "年間労務管理",
    },
  },
];
