<template>
  <div>
    <div class="flex justify-end mb-4">
      <BasicButton @click="openAddLabelModal"> ラベルの追加・変更 </BasicButton>
    </div>

    <DataTable :value="organizationMembers" scrollable>
      <Column
        :field="(om) => `${om.user.lastName}${om.user.firstName}`"
        header="名前"
        style="width: 120px"
      ></Column>
      <Column header="雇用形態" style="width: 200px">
        <template #body="{ data }">
          <Select
            :model-value="
              data.labels
                .filter((l) => !l._destroy && l.labelType === 'employment_type')
                .map((l) => l.labelName)[0]
            "
            :options="
              labelOptions.filter((l) => l.labelType === 'employment_type')
            "
            :option-label="(l) => l.labelName"
            :option-value="(l) => l.labelName"
            filter
            style="width: 100%"
            @update:model-value="updateMemberEmployeeType(data, $event)"
          />
        </template>
      </Column>
      <Column header="役職" style="width: 200px">
        <template #body="{ data }">
          <MultiSelect
            :model-value="
              data.labels
                .filter((l) => !l._destroy && l.labelType === 'position')
                .map((l) => l.labelName)
            "
            :options="labelOptions.filter((l) => l.labelType === 'position')"
            :option-label="(l) => l.labelName"
            :option-value="(l) => l.labelName"
            filter
            class="w-full"
            @update:model-value="updateMemberLabels(data, $event, 'position')"
          >
            <!--template #chipicon="{ item }">
              <span
                class="p-chips-token-icon p-cursor-pointer"
                @click="removeMemberLabels(data, item)"
              >
                <i class="pi pi-times-circle"></i>
              </span>
            </ template -->
          </MultiSelect>
        </template>
      </Column>
      <Column header="部署">
        <template #body="{ data }">
          <MultiSelect
            :model-value="
              data.labels
                .filter((l) => !l._destroy && l.labelType === 'department')
                .map((l) => l.labelName)
            "
            :options="labelOptions.filter((l) => l.labelType === 'department')"
            :option-label="(l) => l.labelName"
            :option-value="(l) => l.labelName"
            filter
            class="w-full"
            @update:model-value="updateMemberLabels(data, $event, 'department')"
          >
            <!--template #chipicon="{ item }">
              <span
                class="p-chips-token-icon p-cursor-pointer"
                @click="removeMemberLabels(data, item)"
              >
                <i class="pi pi-times-circle"></i>
              </span>
            </ template -->
          </MultiSelect>
        </template>
      </Column>
    </DataTable>

    <SimpleModal v-model:visible="showAddModal" title="ラベルの追加">
      <LabelForm
        v-model:label-type="form.labelType"
        v-model:label-name="form.labelName"
        v-model:parent-id="form.parentId"
        :errors="errors"
        :parents="labelOptions"
      />
      <div class="flex justify-end">
        <BasicButton @click="addLabel"> 追加 </BasicButton>
      </div>
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, ref, Ref } from "vue";
import {
  useRouterUtil,
  useOrganization,
  useOrganizationMember,
  useZodScheme,
} from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Simple as SimpleModal } from "/@/vue/components/Molecules";
import { Form as LabelForm } from "/@/vue/components/Organisms/OrganizationMemberLabels";
import {
  OrganizationClient,
  OrganizationMemberClient,
  OrganizationMemberLabelOptionForm,
  OrganizationMemberLabelOptionCheckScheme,
  OrganizationMemberLabelOptionClient,
  OrganizationMemberLabelType,
} from "/@/types";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import Select from "primevue/select";

const { goto } = useRouterUtil();

// organization

const selectedOrganizationId = inject("selectedOrganizationId") as Ref<number>;
const selectedOrganization =
  inject<Ref<OrganizationClient>>("selectedOrganization") ||
  ref<OrganizationClient | undefined>(undefined);

const isManager = computed(() => selectedOrganization.value?.isManager);

const { getOrganizationMembers, updateOrganizationMember } =
  useOrganizationMember();
const { data: organizationMembers, mutate: getOrganizationMembersMutate } =
  getOrganizationMembers(selectedOrganizationId);

const { addLabelOption, getLabelOptions } = useOrganization();

// label

const { data: labelOptionsBase, mutate: getLabelOptionsMutate } =
  getLabelOptions(selectedOrganization);
const labelOptions = computed(() => {
  if (!labelOptionsBase.value) return [];

  return labelOptionsBase.value || [];
});

async function updateMemberEmployeeType(
  om: OrganizationMemberClient,
  event: string
) {
  om.labels = [
    ...om.labels.filter((l) => l.labelType !== "employment_type"),
    {
      labelType: "employment_type",
      labelName: event,
    },
  ];

  try {
    if (await updateOrganizationMember({ id: om.id, labels: om.labels })) {
      getOrganizationMembersMutate();
    } else {
      alert("更新に失敗しました。");
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }
}

async function updateMemberLabels( // will change to updateMemberPositions
  om: OrganizationMemberClient,
  event: string[],
  labelType: OrganizationMemberLabelType
) {
  const otherLabels = om.labels.filter((l) => {
    return l.labelType !== labelType;
  });

  const targetLabels = om.labels.filter((l) => l.labelType === labelType);

  const addLabels = event.filter((e) => {
    return !targetLabels.find((pl) => pl.labelName === e);
  });

  const destroyLabels = targetLabels.filter((pl) => {
    return !event.find((e) => e === pl.labelName);
  });

  const updatedLabels = [
    ...otherLabels,
    ...addLabels.map((al) => {
      return {
        labelType: labelType,
        labelName: al,
      };
    }),
    ...destroyLabels.map((dl) => {
      return {
        id: dl.id,
        labelType: labelType,
        labelName: dl.labelName,
        _destroy: "1",
      };
    }),
  ];

  try {
    if (await updateOrganizationMember({ id: om.id, labels: updatedLabels })) {
      getOrganizationMembersMutate();
    } else {
      alert("更新に失敗しました。");
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }
}

// form

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<OrganizationMemberLabelOptionForm>(
    OrganizationMemberLabelOptionCheckScheme
  );

function resetForm(option?: OrganizationMemberLabelOptionClient) {
  form.id = option?.id || undefined;
  form.labelType = option?.labelType || "employment_type";
  form.labelName = option?.labelName || undefined;
}

// modal

const showAddModal = ref(false);

function openAddLabelModal() {
  resetForm();
  showAddModal.value = true;
  startValidation.value = true;
}

function closeAddLabelModal() {
  resetForm();
  showAddModal.value = false;
}

// updater

async function addLabel() {
  if (!selectedOrganization.value) return;

  try {
    const labelOption = OrganizationMemberLabelOptionCheckScheme.parse(form);

    if (await addLabelOption(selectedOrganization.value, labelOption)) {
      alert("変更依頼を作成しました");
      closeAddLabelModal();
    } else {
      alert(
        "変更の依頼に失敗しました。すでに変更依頼を出しているか、なんらかな問題が発生しました"
      );
    }

    getLabelOptionsMutate();
  } catch {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
  }
}
</script>

<style scoped></style>
