import zod from "zod";
import { z } from "/@/modules/zodUtils";

export const memberApplicationTypes = [
  "holiday",
  "paid",
  "correction",
] as const;
export const MemberApplicationTypeScheme = z.enum(memberApplicationTypes);
export type MemberApplicationType = zod.infer<
  typeof MemberApplicationTypeScheme
>;

export function memberTypeLabel(type: MemberApplicationType): string {
  switch (type) {
    case "holiday":
      return "希望休申請";
    case "paid":
      return "有給申請";
    case "correction":
      return "修正申請";
  }
}

export const memberApplicationStatus = ["pending", "approved", "reject"];

export function memberApplicationStatusLabel(
  status: MemberApplicationStatus
): string {
  switch (status) {
    case "pending":
      return "未承認";
    case "approved":
      return "承認済";
    case "reject":
      return "却下";
  }
}

export const MemberApplicationStatusScheme = z.enum(memberApplicationStatus);
export type MemberApplicationStatus = zod.infer<
  typeof MemberApplicationStatusScheme
>;

export const MemberApplicationScheme = z.object({
  id: z.number().optional(),
  organizationMemberId: z.number().optional(),
  applicationType: MemberApplicationTypeScheme,
  memberApplicationStatus: MemberApplicationStatusScheme,
  date: z.string(),
  correctionStartAt: z.string().optional(),
  correctionFinishAt: z.string().optional(),
});
export type MemberApplication = zod.infer<typeof MemberApplicationScheme>;

export const MemberApplicationCheckScheme = MemberApplicationScheme;

export const MemberApplicationFormScheme = MemberApplicationScheme;

export const MemberApplicationClientScheme = MemberApplicationScheme.extend({
  id: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
  userName: z.string(),
  currentStartAt: z.string().optional(),
  currentFinishAt: z.string().optional(),
});
export type MemberApplicationClient = zod.infer<
  typeof MemberApplicationClientScheme
>;
