<template>
  <div>
    <InputGroup>
      <InputForm
        v-model="modelValue"
        :is-valid="isValid"
        :form-id="formId"
        :autocomplete="autocomplete"
        :form-type="showPassword ? 'text' : 'password'"
      />
      <BasicButton
        button-type="button"
        outlined
        variant="secondary"
        :icon="showPassword ? 'pi pi-eye' : 'pi pi-eye-slash'"
        :tabindex="-1"
        style="width: 3rem"
        @click="showPassword = !showPassword"
      />
    </InputGroup>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, inject, Ref } from "vue";
import { BasicButton, InputForm } from "/@/vue/components/Atom";
import InputGroup from "primevue/inputgroup";

interface Props {
  isValid?: boolean;
  isConfirmation?: boolean;
  formId?: string;
  formType?: string;
  autocomplete?: string;
}

withDefaults(defineProps<Props>(), {
  formType: "password",
  autocomplete: "password",
});

const modelValue = defineModel<string>();
const showPassword = inject<Ref<boolean>>("showPassword");
</script>

<style lang="scss" scoped></style>
