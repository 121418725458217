<template>
  <div>
    <div class="flex items-center justify-between mb-4">
      <Card class="w-full">
        <template #title> 通知一覧 </template>
        <template #content>
          <div
            v-if="notifications.length"
            class="flex flex-col justify-center items-center h-full"
          >
            <div class="w-full">
              <div class="flex justify-between items-center">
                <div class="flex items-center">
                  <p class="text-sm">通知</p>
                </div>
              </div>
              <div class="mt-4">
                <div
                  v-for="(notification, idx) in notifications"
                  :key="notification.id"
                  class="flex justify-between items-center p-2"
                  :class="{
                    'border-t border-surface-200 dark:border-surface-700':
                      idx !== 0,
                  }"
                >
                  <div class="flex flex-col w-full">
                    <div>
                      <Badge
                        :value="notificationActionToString(notification.action)"
                        class="mr-2"
                      />
                    </div>

                    <div class="flex items-center justify-between w-full">
                      <div class="flex flex-wrap items-center">
                        <div style="width: 150px" class="truncate me-2">
                          {{ notification.notifiable.title }}
                        </div>
                        <p class="w-fit truncate">
                          {{ notification.notifiable.content.slice(0, 100) }}
                        </p>
                      </div>
                      <BasicButton
                        class="btn btn-primary"
                        text
                        small
                        @click="gotoDetails(notification)"
                      >
                        詳細ページへ
                      </BasicButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p>通知はありません</p>
          </div>
        </template>
      </Card>
    </div>

    <SimpleModal v-model:visible="showEditModal" title="変更依頼">
      <div class="flex justify-end"></div>
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, reactive, inject } from "vue";
import { luxonNow } from "/@/modules/luxon";
import { createDateForCalendars } from "/@/modules/calendar";
import {
  useRouterUtil,
  useUser,
  useOrganizationMember,
} from "/@/vue/composables";
import { Simple as SimpleModal } from "/@/vue/components/Molecules";
import { BasicButton } from "/@/vue/components/Atom";
import {
  AttendanceForm as AttendanceFormType,
  DateMap,
  OrganizationClient,
} from "/@/types";
import Card from "primevue/card";
import { Ref } from "vue";
import Badge from "primevue/badge";
import { notificationActionToString } from "/@/types/notifications.d";

const { goto } = useRouterUtil();

// user

const { getCurrentUser } = useUser();

// organization

const selectedOrganizationId = inject<Ref<number>>("selectedOrganizationId");
const selectedOrganization = inject<Ref<OrganizationClient>>(
  "selectedOrganization"
);

const { getOrganizationMember } = useOrganizationMember();
const { data: organizationMember } = getOrganizationMember(
  selectedOrganizationId,
  {
    withNotifications: true,
  }
);

const notifications = computed(() => {
  if (organizationMember.value) {
    return organizationMember.value.notifications;
  } else {
    return [];
  }
});

function gotoDetails(notification: any) {
  if (notification.notifiableType === "SystemAnnounce") {
    goto({
      name: "UsersSystemAnnouncesShow",
      params: { id: notification.notifiableId },
    });
  } else {
    goto({
      name: "UsersNotificationsShow",
      params: { id: notification.notifiableId },
    });
  }
}

// date

const today = luxonNow();

const selectedDate = reactive<DateMap>({
  year: today.year,
  month: today.month,
});

const dates = computed(() => {
  return createDateForCalendars(selectedDate);
});

// modal

const showEditModal = ref(false);
</script>

<style scoped></style>
