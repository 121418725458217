<template>
  <div>
    <div class="flex items-center justify-between mb-4">
      <div class="flex items-center">
        <Select
          v-model="selectedDate.year"
          :options="years"
          style="min-width: 100px"
          class="me-4"
        />

        <SelectObject
          v-if="organizationMembers && organizationMembers.length"
          v-model="selectedOrganizationMemberId"
          :option-label="
            (member) => member.user?.lastName + member.user?.firstName
          "
          option-value="id"
          :options="organizationMembers"
          class="me-4"
        />
      </div>
      <BasicButton @click="goto({ name: 'UsersLaborManagementsIndex' })">
        戻る
      </BasicButton>
    </div>

    <Card>
      <template #title> 勤務管理 </template>
      <template #content>
        <div class="flex items-center justify-center">
          <div class="w-full">
            <DataTable :value="months" editMode="cell" scrollable size="small">
              <Column header="月" style="min-width: 100px">
                <template #body="{ data }"> {{ data.month }} </template>
              </Column>
              <Column header="月の日数" style="min-width: 100px">
                <template #body="{ data }">
                  {{ data.dayCount }}
                </template>
              </Column>
              <Column
                header="所定労働日数"
                field="laborDays"
                style="min-width: 100px"
              >
                <template #body="{ data }">
                  {{
                    memberLaborInformationsMap[data.targetMonthStr]?.laborDays
                  }}
                  <i class="pi pi-pencil !text-xs"></i>
                </template>
                <template #editor="{ data, field }">
                  <InputNumber
                    :model-value="
                      memberLaborInformationsMap[data.targetMonthStr]?.laborDays
                    "
                    autofocus
                    class="w-fit"
                    input-class="!py-0 !text-xs"
                    @update:model-value="
                      (e) => updateLaborInfo(e, data.month, field)
                    "
                  />
                </template>
              </Column>
              <Column header="公休日数" style="min-width: 100px">
                <template #body="{ data }">
                  {{
                    memberLaborInformationsMap[data.targetMonthStr]
                      ?.publicHolidayDays
                  }}
                </template>
              </Column>
              <Column header="現在の業務予定数" style="min-width: 100px">
                <template #body="{ data }">
                  {{
                    memberLaborInformationsMap[data.targetMonthStr]
                      ?.currentShiftDays
                  }}
                </template>
              </Column>
              <Column header="現在の業務数" style="min-width: 100px">
                <template #body="{ data }">
                  {{
                    memberLaborInformationsMap[data.targetMonthStr]
                      ?.currentAttendanceDays
                  }}
                </template>
              </Column>

              <ColumnGroup type="footer">
                <Row>
                  <Column footer="合計" footerStyle="text-align:right" />
                  <Column :footer="365" />
                  <Column :footer="totalLaborDays" />
                  <Column>
                    <template #footer="{ data }">
                      <div
                        :class="{ 'text-red-500': totalPublicHolidays < 105 }"
                      >
                        {{ totalPublicHolidays }}
                      </div>
                    </template>
                  </Column>
                  <Column :footer="totalAttendanceDays" />
                  <Column>
                    <template #footer="{ data }">
                      <div :class="{ 'text-red-500': lackShiftDays }">
                        {{ totalShiftDays }}
                      </div>
                    </template>
                  </Column>
                </Row>
              </ColumnGroup>
            </DataTable>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, reactive, inject, Ref } from "vue";
import { luxonNow } from "/@/modules/luxon";
import {
  useOrganizationMember,
  useUser,
  useRouterUtil,
} from "/@/vue/composables";
import { SelectObject, BasicButton } from "/@/vue/components/Atom";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Select from "primevue/select";
import Card from "primevue/card";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import { DateMap, OrganizationClient } from "/@/types";
import { zeroPadding } from "/@/modules/string";
import InputNumber from "primevue/inputnumber";
import { errorHandle } from "/@/modules/error";

const { goto } = useRouterUtil();

// date

const today = luxonNow();
const selectedDate = reactive<DateMap>({
  year: today.year,
  month: undefined,
});

const years = computed(() => {
  const years = [];
  for (let i = 0; i < 5; i++) {
    years.push(today.plus({ years: 1 }).minus({ years: i }).year);
  }
  return years;
});

function countWeekends(year: number, month: number) {
  let saturdays = 0;
  let sundays = 0;

  // 月の最初の日（1日目）から月の最後の日までループ
  let date = new Date(year, month - 1, 1);
  while (date.getMonth() === month - 1) {
    let dayOfWeek = date.getDay();

    if (dayOfWeek === 6) {
      saturdays++;
    } else if (dayOfWeek === 0) {
      sundays++;
    }

    // 次の日へ
    date.setDate(date.getDate() + 1);
  }

  return saturdays + sundays;
}

const months = computed(() => {
  return Array.from({ length: 12 }, (_, i) => i + 1).map((m) => {
    // weekend count = counts of holidays in the month (sat, sun, holiday)
    return {
      month: m,
      dayCount: new Date(selectedDate.year, m, 0).getDate(),
      weekendCount: countWeekends(selectedDate.year, m),
      targetMonthStr: `${selectedDate.year}-${zeroPadding(m, 2)}-01`,
    };
  });
});

// user

const { getCurrentUser } = useUser();
const { data: currentUser } = getCurrentUser();

// organization

const selectedOrganizationId = inject("selectedOrganizationId");
const selectedOrganization = inject<Ref<OrganizationClient>>(
  "selectedOrganization"
);

// organization members

const {
  getOrganizationMembers,
  updateOrganizationMember,
  updateLaborInfo: updateLaborInfoApi,
} = useOrganizationMember();

const { data: organizationMembers, mutate: getOrganizationMembersMutate } =
  getOrganizationMembers(
    selectedOrganizationId,
    {
      withShifts: true.toString(),
      withLaborInformations: true.toString(),
    },
    selectedDate
  );

const selectedOrganizationMemberId = ref<number>();
const selectedOrganizationMember = computed(() => {
  if (!organizationMembers.value) return;
  return organizationMembers.value.find(
    (m) => m.id === selectedOrganizationMemberId.value
  );
});

// labor information

const memberLaborInformationsMap = computed(() => {
  if (!selectedOrganizationMember.value) return {};

  let m = {};

  selectedOrganizationMember.value.laborInformations.forEach((l) => {
    m[l.targetMonth] = l;
  });

  return m;
});

const totalLaborDays = computed(() => {
  return selectedOrganizationMember.value?.laborInformations.reduce(
    (acc, current) => acc + current.laborDays,
    0
  );
});

const totalPublicHolidays = computed(() => {
  if (!totalLaborDays.value) return 0;

  return 365 - totalLaborDays.value;
});

const totalAttendanceDays = computed(() => {
  return selectedOrganizationMember.value?.laborInformations.reduce(
    (acc, current) => acc + current.currentAttendanceDays,
    0
  );
});

const totalShiftDays = computed(() => {
  return selectedOrganizationMember.value?.laborInformations.reduce(
    (acc, current) => acc + current.currentShiftDays,
    0
  );
});

const lackShiftDays = computed(() => {
  if (!totalLaborDays.value || !totalShiftDays.value) return true;

  return totalLaborDays.value - totalShiftDays.value > 0;
});

const needSign = computed(() => {
  return lackShiftDays.value;
});

async function updateLaborInfo(e: any, month: number, field: string) {
  try {
    if (!selectedOrganizationMemberId.value) return;

    if (
      await updateLaborInfoApi(
        selectedOrganizationMemberId.value,
        { year: selectedDate.year, month },
        field,
        e
      )
    ) {
      getOrganizationMembersMutate();
    } else {
      console.error("updateLaborInfo failed");
    }
  } catch (e) {
    errorHandle(e);
    return;
  }
}
</script>

<style scoped></style>
