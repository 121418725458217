<template>
  <div>
    <Select
      v-model="modelValue"
      :option-label="optionLabel"
      :option-value="optionValue"
      :options="uniqueOptions"
      style="min-width: 150px"
      :show-clear="showClear"
    />
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { computed, onMounted } from "vue";
import Select from "primevue/select";

const modelValue = defineModel<number>();

const props = withDefaults(
  defineProps<{
    options: any[];
    optionValue?: string;
    optionLabel: ((o: any) => string) | string;
    disableAutoSelect?: boolean;
    showClear?: boolean;
  }>(),
  {
    optionValue: "id",
    optionLabel: "label",
    disableAutoSelect: false,
    showClear: false,
  }
);

const uniqueOptions = computed(() => {
  return _.uniqBy(props.options, props.optionValue);
});

const selected = computed(() => {
  return props.options.find((o) => o.id === modelValue.value);
});

onMounted(() => {
  if (!modelValue.value && !props.disableAutoSelect) {
    modelValue.value = props.options[0].id;
  }
});
</script>

<style lang="scss" scoped></style>
