import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { Shift, ShiftScheme } from "/@/types/shift.d";
import { UserClientScheme, UserScheme } from "/@/types/user.d";
import { LaborInformationClientScheme } from "/@/types/laborInformation.d";
import { NotificationClientScheme } from "/@/types/notifications.d";
import { PhoneNumber } from "libphonenumber-js";

// label

// 組織管理者 役員 アルバイト 契約社員

export const organizationMemberLabelTypeForms = [
  "employment_type",
  "position",
  "department",
] as const;

export const organizationMemberLabelTypes = [
  "manager",
  ...organizationMemberLabelTypeForms,
];
export const OrganizationMemberLabelTypeScheme = z.enum(
  organizationMemberLabelTypeForms
);
export type OrganizationMemberLabelType = zod.infer<
  typeof OrganizationMemberLabelTypeScheme
>;

export function organizationMemberLabelTypeLabel(
  labelType: OrganizationMemberLabelType
) {
  switch (labelType) {
    case "employment_type":
      return "雇用形態";
    case "position":
      return "役職";
    case "manager":
      return "組織管理者";
    case "department":
      return "部署";
  }
}

export const OrganizationMemberLabelOptionScheme = z.object({
  id: z.number().optional(),
  labelType: OrganizationMemberLabelTypeScheme,
  labelName: z.string().trim(),
});

export type OrganizationMemberLabelOption = zod.infer<
  typeof OrganizationMemberLabelOptionScheme
>;

export const OrganizationMemberLabelOptionFormScheme =
  OrganizationMemberLabelOptionScheme.partial();

export type OrganizationMemberLabelOptionForm = zod.infer<
  typeof OrganizationMemberLabelOptionFormScheme
>;

export const OrganizationMemberLabelOptionCheckScheme =
  OrganizationMemberLabelOptionScheme;

export const OrganizationMemberLabelOptionClientScheme =
  OrganizationMemberLabelOptionScheme.extend({
    id: z.number(),
    organizationId: z.number(),
  });
export type OrganizationMemberLabelOptionClient = zod.infer<
  typeof OrganizationMemberLabelOptionClientScheme
>;

export const OrganizationMemberLabelScheme = z.object({
  id: z.number().optional(),
  organizationMemberId: z.number().optional(),
  labelType: OrganizationMemberLabelTypeScheme,
  labelName: z.string().trim(),
});

// info

// additional info
export const BusinessAccountScheme = z.object({
  id: z.number().optional().nullable(),
  email: z.string().email().nullable(),
  password: z
    .string()
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,32}$/i,
      "パスワードは半角英字の8～32字で、小文字・大文字・数字を各1つ以上含む必要があります。"
    )
    .nullable(),
  serviceName: z
    .string()
    .trim()
    .min(1)
    .max(50)
    .optional()
    .or(z.literal(""))
    .nullable(),
  firstName: z
    .string()
    .trim()
    .min(1)
    .max(50)
    .optional()
    .or(z.literal("").nullable())
    .nullable(),
  lastName: z
    .string()
    .trim()
    .min(1)
    .max(50)
    .optional()
    .or(z.literal(""))
    .nullable(),
  birthday: z.string().trim().min(5).optional().or(z.literal("")).nullable(),
  phoneNumber: z
    .string()
    .trim()
    .min(10)
    .max(11)
    .optional()
    .or(z.literal(""))
    .nullable(),
  createdOn: z.string().optional().nullable(), // 実際のデータ追加時と、Log 上での追加が異なる場合があることへの対応
  disusedOn: z.string().optional().nullable(),
  _destroy: z.boolean().optional().nullable(),
});

export type BusinessAccount = zod.infer<typeof BusinessAccountScheme>;

export const simTypes = ["sim", "esim"] as const;
export const simCompanyTypes = [
  "docomo",
  "au",
  "softbank",
  "uqmobile",
  "OCN",
  "LIBMO",
  "Y!mobile",
  "eximo",
  "ocn",
  "irumo",
] as const;
export const simContractTypes = ["individual", "business"] as const;
export const SimContractType = z.enum(simContractTypes);
export type SimContractType = zod.infer<typeof SimContractType>;

export function simContractTypeLabel(t: SimContractType) {
  switch (t) {
    case "individual":
      return "個人";
    case "business":
      return "法人";
  }
}

export const BusinessPhoneScheme = z.object({
  id: z.number().optional().nullable(),
  startOn: z.string().optional().nullable(),
  returnOn: z.string().optional().nullable(),
  simStartOn: z.string().optional().nullable(),
  leastOn: z.string().optional().nullable(), // 最低利用期間
  phoneNumber: z
    .string()
    .trim()
    .max(11)
    .optional()
    .or(z.literal(""))
    .nullable(),
  simType: z.enum(simTypes).nullable(),
  simCompany: z.enum(simCompanyTypes).nullable(),
  simContractType: z.enum(simContractTypes).nullable(),
  simContractDetails: z
    .string()
    .trim()
    .max(300)
    .optional()
    .or(z.literal(""))
    .nullable(),
  deviceType: z.string().trim().max(50).optional().or(z.literal("")).nullable(),
  deviceCapacity: z
    .string()
    .trim()
    .max(50)
    .optional()
    .or(z.literal(""))
    .nullable(),
  deviceColor: z
    .string()
    .trim()
    .max(50)
    .optional()
    .or(z.literal(""))
    .nullable(),
  imei: z.string().trim().max(50).optional().or(z.literal("")).nullable(),
  case: z.string().trim().max(50).optional().or(z.literal("")).nullable(),
  passcode: z.string().trim().max(50).optional().or(z.literal("")).nullable(),
  remarks: z.string().trim().max(300).optional().or(z.literal("")).nullable(),
  _destroy: z.boolean().optional().nullable(),
});

export type BusinessPhone = zod.infer<typeof BusinessPhoneScheme>;

export const BusinessPcScheme = z.object({
  id: z.number().optional().nullable(),
  startOn: z.string().optional().nullable(),
  returnOn: z.string().optional().nullable(),
  simStartOn: z.string().optional().nullable(),
  leastOn: z.string().optional().nullable(), // 最低利用期間
  phoneNumber: z
    .string()
    .trim()
    .max(11)
    .optional()
    .or(z.literal(""))
    .nullable(),
  simType: z.enum(simTypes).nullable(),
  simCompany: z.enum(simCompanyTypes).nullable(),
  simContractType: z.enum(simContractTypes).nullable(),
  simContractDetails: z
    .string()
    .trim()
    .max(300)
    .optional()
    .or(z.literal(""))
    .nullable(),
  deviceType: z.string().trim().max(50).optional().or(z.literal("")).nullable(),
  deviceCapacity: z
    .string()
    .trim()
    .max(50)
    .optional()
    .or(z.literal(""))
    .nullable(),
  deviceColor: z
    .string()
    .trim()
    .max(50)
    .optional()
    .or(z.literal(""))
    .nullable(),
  imei: z.string().trim().max(50).optional().or(z.literal("")).nullable(),
  case: z.string().trim().max(50).optional().or(z.literal("")).nullable(),
  passcode: z.string().trim().max(50).optional().or(z.literal("")).nullable(),
  remarks: z.string().trim().max(300).optional().or(z.literal("")).nullable(),
  _destroy: z.boolean().optional().nullable(),
});

export const bankAccountTypes = ["checking", "savings"] as const;
export const BankAccountTypeScheme = z.enum(bankAccountTypes);

export const OrganizationMemberInfoScheme = z.object({
  bankName: z.string().trim().min(1).max(50).optional(), // 銀行名
  bankBranchName: z.string().trim().min(1).max(50).optional(), // 支店名
  bankAccountType: BankAccountTypeScheme, // 普通 当座
  bankAccountNumber: z.string().trim().min(1).max(50).optional(), // 口座番号
  bankAccountHolder: z.string().trim().min(1).max(50).optional(), // 口座名義
  businessAccounts: BusinessAccountScheme.array().optional(), // 業務用メールアドレス
  // createdAt: z.string().optional(), アカウント作成日
  businessPhones: BusinessPhoneScheme.array().optional(), // 業務用電話番号
});

// member

export const OrganizationMemberScheme = z
  .object({
    id: z.number().optional(),
    organizationId: z.number().optional(),
    userId: z.number().optional(),
    code: z.string(),
    joiningOn: z.string(),
    leavingOn: z.string().nullable().optional(),
    leavingReason: z.string().nullable().optional(),
  })
  .merge(OrganizationMemberInfoScheme)
  .merge(UserScheme.partial());

export type OrganizationMember = zod.infer<typeof OrganizationMemberScheme>;

export const OrganizationMemberCheckScheme = OrganizationMemberScheme.partial();

export const OrganizationMemberClientScheme = OrganizationMemberScheme.extend({
  id: z.number(),
  userId: z.number(),
  organizationId: z.number(),
  requesterId: z.number().optional(),
  user: UserClientScheme,
  labels: OrganizationMemberLabelScheme.array(),
  laborInformations: LaborInformationClientScheme.array(),
  notifications: NotificationClientScheme.array(),
});
export type OrganizationMemberClient = zod.infer<
  typeof OrganizationMemberClientScheme
>;

export const OrganizationMemberFormScheme = OrganizationMemberScheme.partial({
  id: true,
  code: true,
  joiningOn: true,
  leavingOn: true,
  leavingReason: true,
}).extend({
  shifts: ShiftScheme.array(),
  labels: OrganizationMemberLabelScheme.array(),
});

export type OrganizationMemberForm = zod.infer<
  typeof OrganizationMemberFormScheme
>;
