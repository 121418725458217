<template>
  <div id="index">
    <h1 id="page-title">Log</h1>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, watch } from "vue";
import { useRouterUtil, useUser } from "/@/vue/composables";
import { updatePreset } from "@primevue/themes";

const { goto } = useRouterUtil();

const { getCurrentUser } = useUser();
const { data: currentUser } = getCurrentUser();

watch(currentUser, (newVal) => {
  if (newVal) {
    goto({ name: "UsersIndex" });
  }
});

onMounted(() => {
  if (currentUser.value) {
    goto({ name: "UsersIndex" });
  }
});

function changeToDark() {
  document.body.classList.toggle("p-dark-mode");
}

function changeToAmber() {
  updatePreset({
    semantic: {
      primary: {
        50: "{indigo.50}",
        100: "{indigo.100}",
        200: "{indigo.200}",
        300: "{indigo.300}",
        400: "{indigo.400}",
        500: "{indigo.500}",
        600: "{indigo.600}",
        700: "{indigo.700}",
        800: "{indigo.800}",
        900: "{indigo.900}",
        950: "{indigo.950}",
      },
    },
  });
}
</script>
