<template>
  <div>
    <div
      v-if="!mobile"
      class="flex justify-between"
      :class="{
        'w-full': !narrow,
        'p-3': !narrow,
      }"
    >
      <div class="flex flex-row items-center">
        <div
          class="bg-orange-400"
          :class="{
            'me-2': !narrow,
            'my-3': narrow,
          }"
          :style="
            narrow
              ? { width: '20px', height: '20px' }
              : { width: '35px', height: '35px' }
          "
        ></div>
        <h1 v-if="!narrow" class="text-lg font-black">Log</h1>
      </div>
      <BasicButton
        v-if="!mobile && !narrow"
        icon="pi pi-angle-double-left"
        variant="secondary"
        @click="$emit('toggleHeader')"
      />
    </div>

    <BasicButton
      v-if="!mobile && narrow"
      icon="pi pi-angle-double-right"
      variant="secondary"
      @click="$emit('toggleHeader')"
    />

    <div style="padding: var(--p-menu-item-padding)">
      <template v-if="narrow">
        <Button
          icon="pi pi-building"
          severity="secondary"
          text
          @click="toggleShowSelectOrganization"
        />
        <Popover ref="showSelectOrganization">
          <SelectObject
            v-if="organizations && organizations.length"
            v-model="selectedOrganizationId"
            option-label="name"
            option-value="id"
            :options="organizations"
            style="min-width: 150px"
          />
        </Popover>
      </template>
      <template v-else>
        <div
          v-if="organizations && organizations.length"
          class="flex items-center"
        >
          <i
            class="pi pi-building mx-2"
            style="color: var(--p-menu-item-icon-color)"
          ></i>
          <SelectObject
            v-model="selectedOrganizationId"
            option-label="name"
            option-value="id"
            :options="organizations"
            style="min-width: 150px"
          />
        </div>
      </template>
    </div>

    <Menu
      :model="headerItems"
      class="without-border"
      :class="{ narrow: narrow }"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, inject, ref } from "vue";
import { useRouterUtil } from "/@/vue/composables";
import { UserClient } from "/@/types";
import { BasicButton, SelectObject } from "/@/vue/components/Atom";
// import TieredMenu from "primevue/tieredmenu";
import Menu from "primevue/menu";
import Popover from "primevue/popover";
import Button from "primevue/button";
import type { MenuItem } from "primevue/menuitem";

const props = defineProps<{
  user: UserClient;
  narrow?: boolean;
  mobile?: boolean;
}>();

const emit = defineEmits<{
  (e: "toggleHeader"): void;
  (e: "logout"): void;
}>();

const { goto } = useRouterUtil();

// organizations

const organizations = computed(() => {
  return props.user.organizations;
});
const selectedOrganizationId = inject("selectedOrganizationId");

const showSelectOrganization = ref();

function toggleShowSelectOrganization(e: Event) {
  showSelectOrganization.value.toggle(e);
}

// header items

function commandHandler(command: () => void) {
  return () => {
    command();
    if (props.mobile) {
      emit("toggleHeader");
    }
  };
}

const labelAttributes = computed(() => {
  if (!props.user) {
    return {};
  }

  return props.user.labelAttributes;
});

const statusAttributes = computed(() => {
  if (!props.user) {
    return {};
  }

  return props.user.statusAttributes;
});

const hasManagedOrganization = computed(() => {
  return statusAttributes.value.has_managed_organizations;
});

const headerItems = computed<MenuItem[]>(() => {
  return [
    {
      label: props.narrow ? "" : "Menu",
      items: [
        {
          label: props.narrow ? "" : "ホーム",
          icon: "pi pi-home",
          visible: !!props.user,
          command: commandHandler(() => goto({ name: "UsersIndex" })),
        },
        {
          label: props.narrow ? "" : "出勤予定",
          icon: "pi pi-calendar",
          visible: !!props.user,
          command: commandHandler(() => goto({ name: "UsersCalendarIndex" })),
        },
        {
          label: props.narrow ? "" : "出勤記録",
          icon: "pi pi-address-book",
          visible: !!props.user,
          command: commandHandler(() =>
            goto({ name: "UsersAttendancesIndex" })
          ),
        },
        {
          label: props.narrow ? "" : "承認リクエスト",
          icon: "pi pi-check",
          visible: !!props.user,
          command: commandHandler(() =>
            goto({ name: "UsersApprovalRequestsIndex" })
          ),
        },
        {
          label: props.narrow ? "" : "マイページ",
          icon: "pi pi-user",
          visible: !!props.user,
          command: commandHandler(() => goto({ name: "UsersShow" })),
        },
      ],
    },
    {
      label: props.narrow ? "" : "Management Menu",
      visible: hasManagedOrganization.value,
      items: [
        {
          label: props.narrow ? "" : "シフト管理",
          icon: "pi pi-calendar",
          command: commandHandler(() => goto({ name: "UsersShiftsIndex" })),
        },
        {
          label: props.narrow ? "" : "労務管理",
          icon: "pi pi-briefcase",
          command: commandHandler(() =>
            goto({ name: "UsersLaborManagementsIndex" })
          ),
        },

        {
          label: props.narrow ? "" : "有給管理",
          icon: "pi pi-money-bill",
          command: commandHandler(() =>
            goto({ name: "UsersPaidManagementsIndex" })
          ),
        },
        {
          label: props.narrow ? "" : "組織情報・管理",
          icon: "pi pi-users",
          command: commandHandler(() =>
            goto({ name: "UsersOrganizationsIndex" })
          ),
        },
      ],
    },
    {
      label: "",
      items: [
        {
          label: props.narrow ? "" : "ログアウト",
          icon: "pi pi-sign-out",
          visible: !!props.user,
          command: commandHandler(() => emit("logout")),
        },
      ],
    },
    {
      label: props.narrow ? "" : "管理画面",
      icon: "pi pi-cog",
      visible: !!labelAttributes.value.systemAdmin,
      command: commandHandler(() => goto({ name: "AdminIndex" })),
    },
    {
      label: "業務記録",
      icon: "pi pi-book",
      visible: !!props.user && false,
      command: commandHandler(() => goto({ name: "UsersWorkLogsIndex" })),
    },
    {
      label: "業務計画",
      icon: "pi pi-list",
      visible: !!props.user && false,
      command: commandHandler(() => goto({ name: "UsersPlansIndex" })),
    },
    {
      label: "資料共有",
      icon: "pi pi-file",
      visible: !!props.user && false,
      command: commandHandler(() => goto({ name: "UsersArticlesIndex" })),
    },
  ];
});
</script>

<style lang="scss">
.without-border {
  &.p-tieredmenu,
  &.p-menu {
    border: none !important;
    --p-drawer-content-padding: 0.5rem;
    --p-tieredmenu-item-padding: 1rem 0.75rem;
  }
}

.p-menu.narrow {
  min-width: auto;

  .p-menu-item-link {
    gap: 0;
  }
}
</style>
