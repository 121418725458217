import { computed } from "vue";
import { useMq } from "vue3-mq";

export function useMqUtils() {
  const mq = useMq();
  const isMobile = computed(() => ["xs", "sm", "md"].includes(mq.current));
  const isXs = computed(() => mq.current === "xs");
  const isSm = computed(() => mq.current === "sm");
  const isMd = computed(() => mq.current === "md");

  const cardWidth = computed(() => {
    return isMobile.value
      ? { width: "100%" }
      : { "max-width": "calc(100vw - (208px + 3.5rem))" };
  });

  return {
    isMobile,
    isMd,
    isSm,
    isXs,
    cardWidth,
  };
}
