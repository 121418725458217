<template>
  <div>
    <div class="flex items-center justify-between mb-4">
      <div class="flex items-center">
        <SelectObject
          v-if="organizationMembers && organizationMembers.length"
          v-model="selectedOrganizationMemberId"
          :option-label="
            (member) => member.user?.lastName + member.user?.firstName
          "
          option-value="id"
          :options="organizationMembers"
          class="me-4"
        />
      </div>
    </div>

    <Card v-if="selectedOrganizationMemberId" :style="cardWidth" class="mb-4">
      <template #title>
        <div class="flex justify-between items-center">
          <span>
            {{ selectedOrganizationMember?.user.fullName }}の有給情報
          </span>
        </div>
      </template>
      <template #content>
        <div class="overflow-scroll w-100">
          <DataTable
            v-if="currentLaborInfo"
            :value="[selectedOrganizationMember]"
            editMode="cell"
            scrollable
            class="mb-4"
          >
            <Column
              :field="(m) => m.user.lastName + m.user.firstName"
              header="名前"
              style="min-width: 80px"
            ></Column>
            <Column header="社員番号" field="code"></Column>
            <Column
              header="雇用形態"
              :field="
                (om) =>
                  om.labels
                    .filter((l) => l.labelType === 'employment_type')
                    .map((l) => l.labelName)
                    .join()
              "
              style="min-width: 120px"
            ></Column>
            <Column
              header="入社日"
              :field="(m) => basicFormatter(m.joiningOn, 'withoutDateDay')"
              style="min-width: 120px"
            ></Column>
            <Column header="有給残日数">
              <template #body="{ data }">
                {{ currentLaborInfo.leastPaidHolidayDays }}
              </template>
            </Column>
            <Column header="取得済み日数">
              <template #body="{ data }">
                {{ currentLaborInfo.paidHolidayDays }}
              </template>
            </Column>
            <Column header="消滅予定の有給">
              <template #body="{ data }">
                {{ currentLaborInfo.expirePaidHolidayDays }}
              </template>
            </Column>
            <Column header="繰越日数">
              <template #body="{ data }">
                {{ currentLaborInfo.leastPaidHolidayDays }}
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </Card>

    <Panel
      v-if="selectedOrganizationMemberId"
      :style="cardWidth"
      class="mb-4"
      toggleable
    >
      <template #header>
        <div class="flex justify-between items-center">
          <span class="text-lg"> 有給使用履歴 </span>
        </div>
      </template>
      <div class="overflow-scroll w-100">
        <DataTable :value="usedPaidHolidaysHistory" editMode="cell" scrollable>
          <Column header="取得日">
            <template #body="{ data }">
              {{ basicFormatter(data.date) }}
            </template>
          </Column>
          <Column header="取得理由"></Column>
          <Column header="承認状況">
            <template #body="{ data }">
              {{ memberApplicationStatusLabel(data.memberApplicationStatus) }}
            </template>
          </Column>
        </DataTable>
      </div>
    </Panel>

    <Panel v-if="selectedOrganizationMemberId" :style="cardWidth" toggleable>
      <template #header>
        <div class="flex justify-between items-center">
          <span class="text-lg"> 有給付与履歴 </span>
        </div>
      </template>

      <div class="overflow-scroll w-100">
        <DataTable :value="paidHolidaysHistory" editMode="cell" scrollable>
          <Column header="付与日">
            <template #body="{ data }">
              {{ basicFormatter(data?.date) }}
            </template>
          </Column>
          <Column header="付与数">
            <template #body="{ data }">
              {{ data?.count }}
            </template></Column
          >
          <Column header="有効">
            <template #body="{ data }">
              {{ data?.valid ? "有効" : "無効" }}
            </template>
          </Column>
        </DataTable>
      </div>
    </Panel>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, reactive, ref } from "vue";
import { useRouterUtil, useMqUtils } from "/@/vue/composables";
import { basicFormatter, luxonNow } from "/@/modules/luxon";
import { DateMap } from "/@/types";
import { SelectObject } from "/@/vue/components/Atom";
import { Ref } from "vue";
import { OrganizationClient } from "/@/types";
import { useOrganizationMember } from "/@/vue/composables";
import {
  organizationMemberLabelTypeForms,
  organizationMemberLabelTypeLabel,
  memberApplicationStatusLabel,
} from "/@/types";
import Select from "primevue/select";
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { zeroPadding } from "/@/modules/string";
import Panel from "primevue/panel";

const { goto } = useRouterUtil();
const { cardWidth } = useMqUtils();

// date

const today = luxonNow();
const selectedDate = reactive<DateMap>({
  year: today.year,
  month: today.month,
});

// member

// organization

const selectedOrganizationId = inject("selectedOrganizationId");
const selectedOrganization = inject<Ref<OrganizationClient>>(
  "selectedOrganization"
);

const {
  getOrganizationMembers,
  updateOrganizationMember,
  updateLaborInfo: updateLaborInfoApi,
} = useOrganizationMember();

const { data: organizationMembers, mutate: getOrganizationMembersMutate } =
  getOrganizationMembers(
    selectedOrganizationId,
    {
      withLaborInformations: true.toString(),
    },
    selectedDate
  );

const selectedOrganizationMemberId = ref<number>();
const selectedOrganizationMember = computed(() => {
  if (!organizationMembers.value) return;
  return organizationMembers.value.find(
    (m) => m.id === selectedOrganizationMemberId.value
  );
});

// laborInfo

const currentLaborInfo = computed(() => {
  if (!selectedOrganizationMember.value) return;
  return selectedOrganizationMember.value.laborInformations.find(
    (li) =>
      li.targetMonth ===
      `${selectedDate.year}-${zeroPadding(selectedDate.month, 2)}-01`
  );
});

// paid

// 有給使用履歴

const usedPaidHolidaysHistory = computed(() => {
  if (!selectedOrganizationMember.value) return [];
  return currentLaborInfo.value?.usedPaidHolidaysHistory;
});

// 有給獲得履歴
const paidHolidaysHistory = computed(() => {
  if (!selectedOrganizationMember.value) return [];
  return currentLaborInfo.value?.paidHolidaysHistory;
});
</script>

<style lang="scss" scoped></style>
