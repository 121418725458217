<template>
  <div id="user" class="h-full">
    <UserHeader
      ref="userHeader"
      v-if="!isIndexPages"
      :user="user"
      class="md:hidden md:-mx-8 md:-mt-4 -mx-4 -mt-4 sticky top-0 left-0 border-b"
      style="border-color: var(--p-divider-border-color)"
    />
    <div
      class="flex md:pt-0 pt-4 flex-col md:flex-row"
      :style="{
        height: isMobile ? 'auto' : 'calc(100vh - 2rem)',
      }"
    >
      <div v-if="!isIndexPages && user" class="me-4 md:block hidden h-full">
        <Card class="h-full" id="user-nav-menu-card">
          <template #content>
            <UserNavMenu
              :user="user"
              :narrow="!showNavMenu"
              :style="{
                'max-width': showNavMenu ? '350px' : '100px',
              }"
              class="flex flex-col items-center justify-center"
              @logout="handleLogout"
              @toggle-header="showNavMenu = !showNavMenu"
            />
          </template>
        </Card>
      </div>

      <div
        class="flex flex-col w-full ms-0"
        :class="{
          'md:ms-0': !isIndexPages && user,
        }"
      >
        <div
          v-if="showToggleOptionPageInMobile"
          class="md:py-4 items-center justify-between flex py-2"
        >
          <div class="flex items-center">
            <h1
              v-if="showTitleAndAccountInfo"
              class="text-2xl font-bold me-4"
            >
              <span>{{ pageTitle }}</span>
            </h1>
            <div id="users-nav-area"></div>
          </div>

          <AccountInfo
            v-if="user && showTitleAndAccountInfo"
            :user="user"
          />
        </div>
        <div class="flex w-full">
          <router-view
            id="user-root"
            class="md:overflow-scroll w-full pb-8"
            :style="{
              height: isMobile ? 'auto' : 'calc(100vh - (2rem + 56px))',
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, provide, onMounted, watch } from "vue";
import {
  useAuthorize,
  useRouterUtil,
  useUser,
  useMqUtils,
} from "/@/vue/composables";
import { UserNavMenu, UserHeader } from "/@/vue/components/Layouts";
import { AccountInfo } from "/@/vue/components/Organisms/Users";
import Card from "primevue/card";
import {
  defaultOrganizationConfig,
  OrganizationClient,
  OrganizationConfig,
} from "/@/types";

const { isMobile } = useMqUtils();

const { getCurrentUser } = useUser();
const { data: user } = getCurrentUser();

// organization

const organizations = computed(() => {
  if (!user.value) return [];
  return user.value.organizations;
});

const selectedOrganizationId = ref<number>();
provide("selectedOrganizationId", selectedOrganizationId);

const selectedOrganization = ref<OrganizationClient>();
provide("selectedOrganization", selectedOrganization);

const organizationConfig = ref<OrganizationConfig>();
provide("organizationConfig", organizationConfig);

watch(selectedOrganizationId, (o) => {
  if (o) {
    selectedOrganization.value = organizations.value.find(
      (org) => org.id === o
    );

    organizationConfig.value = {
      baseStartAt:
        selectedOrganization.value?.baseStartAt ||
        defaultOrganizationConfig.baseStartAt,
      baseFinishAt:
        selectedOrganization.value?.baseFinishAt ||
        defaultOrganizationConfig.baseFinishAt,
      baseTimeUnit:
        selectedOrganization.value?.baseTimeUnit ||
        defaultOrganizationConfig.baseTimeUnit,
    };
  }
});

const { currentRouteName, currentRouteNeedLogin, currentRouteMeta } =
  useRouterUtil();

const pageTitle = computed(() => {
  /*
  if (currentRouteQuery.value?.title) {
    return currentRouteQuery.value.title;
  }
  */
  return currentRouteMeta.value?.title;
});

const isIndexPages = computed(() => {
  if (!currentRouteName.value) return false;

  return !currentRouteNeedLogin.value;
});

const showToggleOptionPageInMobile = computed(() => {
  return (
    !isMobile.value || (isMobile.value && ["UsersCalendarIndex"].includes(currentRouteName.value))
  );
});

const showTitleAndAccountInfo = computed(() => {
  return !isMobile.value;
});

const showNavMenu = ref(true);
const showOptionMenu = ref(false);

provide("showOptionMenu", showOptionMenu);

onMounted(() => {
  showOptionMenu.value = true;
});

const userHeader = ref();

const headerHeight = computed(() => {
  if (!userHeader.value) return "58px";
  return userHeader.value.$el.clientHeight + "px";
});

const { reload } = useRouterUtil();
const { logout } = useAuthorize();

async function handleLogout() {
  if (!confirm("ログアウトしますか？")) return;

  await logout();
  reload();
}
</script>

<style lang="scss">
#user-root {
  // margin-bottom: 2rem;
}

#user-header {
  .p-toolbar {
    border: none;
  }

  .p-button {
    border: none;
  }
}

#user-nav-menu-card {
  --p-card-body-padding: 0.25rem;
}

* {
  transition: width 0.35s ease;
}
</style>
