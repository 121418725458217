<template>
  <div>
    <div class="flex items-center justify-between mb-4">
      <div></div>
      <div v-if="isManager" class="w-auto">
        <BasicButton
          class="md:me-4"
          @click="goto({ name: 'UsersOrganizationMemberLabelsIndex' })"
        >
          ラベル管理画面
        </BasicButton>

        <BasicButton @click="goto({ name: 'UsersNew' })">
          ユーザーの追加
        </BasicButton>
      </div>
    </div>

    <Card v-if="selectedOrganization" class="mb-4">
      <template #content>
        <Accordion value="">
          <AccordionPanel value="0" class="!border-0">
            <AccordionHeader>
              <div class="flex justify-between items-center w-full">
                <span>組織情報</span>
                <BasicButton
                  v-if="selectedOrganization.isManager"
                  class="me-4"
                  disabled
                  @click="
                    goto({
                      name: 'UsersOrganizationsEdit',
                      params: { id: selectedOrganization.id },
                    })
                  "
                >
                  編集
                </BasicButton>
              </div>
            </AccordionHeader>
            <AccordionContent>
              <ShowFormItemWrapper title="組織名">
                {{ selectedOrganization.name }}
              </ShowFormItemWrapper>
              <ShowFormItemTel
                title="電話番号"
                :tel="selectedOrganization.phoneNumber"
              />
              <ShowFormItemAddress
                title="住所"
                v-bind="selectedOrganization"
                without-divider
              />
            </AccordionContent>
          </AccordionPanel>
        </Accordion>
      </template>
    </Card>

    <Card
      v-if="isManager && organizationMembers && organizationMembers.length > 1"
      class="mb-4"
      :style="cardWidth"
    >
      <template #content>
        <Accordion value="0">
          <AccordionPanel value="0" class="!border-0">
            <AccordionHeader>
              <div class="flex justify-between w-full">
                <div class="flex items-center justify-between w-full">
                  <span class="me-4">メンバー情報</span>
                </div>
              </div>
            </AccordionHeader>
            <AccordionContent>
              <div>
                <div class="mb-4">
                  <Fieldset legend="表示設定" toggleable class="">
                    <div class="flex flex-col p-2">
                      <div
                        v-for="(op, i) in checkboxOptions"
                        class="flex items-center"
                        :class="{ 'mt-4': i !== 0 }"
                      >
                        <Checkbox
                          v-model="visibility[op.value]"
                          binary
                          class="me-2"
                          :name="op.value"
                          :inputId="op.value"
                        />
                        <label :for="op.value">{{ op.label }}</label>
                      </div>
                    </div>
                  </Fieldset>
                </div>

                <DataTable
                  :value="organizationMembers"
                  striped-rows
                  scrollable
                  selection-mode="single"
                  size="small"
                  @update:selection="memberSelect"
                >
                  <Column header="認証" style="min-width: 50px">
                    <template #body="{ data }">
                      <span v-if="!data.user.confirmed">
                        <i class="pi pi-exclamation-triangle text-red-500"></i>
                      </span>
                    </template>
                  </Column>
                  <Column
                    :field="(om) => `${om.user.lastName}${om.user.firstName}`"
                    header="名前"
                    style="min-width: 80px"
                  ></Column>
                  <Column
                    v-if="visibility['showBasicInfo']"
                    :field="
                      (om) => `${om.user.lastNameKana}${om.user.firstNameKana}`
                    "
                    header="ヨミ"
                    style="min-width: 80px"
                  ></Column>
                  <Column
                    v-if="visibility['showBasicInfo']"
                    header="生年月日"
                    style="min-width: 250px"
                  >
                    <template #body="{ data }">
                      {{
                        basicFormatter(data.user.birthday, "withoutDateDay")
                      }}({{ basicFormatter(data.user.birthday, "japanize") }})
                    </template>
                  </Column>
                  <Column
                    v-if="visibility['showBasicInfo']"
                    header="年齢"
                    style="min-width: 50px"
                  >
                    <template #body="{ data }">
                      {{ data.user.age }}
                    </template>
                  </Column>
                  <Column
                    v-if="visibility['showBasicInfo']"
                    header="住所"
                    style="min-width: 450px"
                  >
                    <template #body="{ data }">
                      {{
                        "〒" +
                        data.user.postalCode.slice(0, 3) +
                        "-" +
                        data.user.postalCode.slice(3, 7) +
                        " " +
                        data.user.addressLevel1 +
                        data.user.addressLevel2 +
                        data.user.addressLine1 +
                        (data.user.addressLine2 || "")
                      }}
                    </template>
                  </Column>
                  <Column
                    v-if="visibility['showBasicInfo']"
                    header="最寄り駅"
                    style="min-width: 100px"
                  >
                    <template #body="{ data }">
                      {{ data.user.nearestStation }}
                    </template>
                  </Column>
                  <Column
                    v-if="visibility['showBasicInfo']"
                    :field="(om) => om.user.email"
                    header="メールアドレス"
                    style="min-width: 300px"
                  >
                  </Column>
                  <Column
                    v-if="visibility['showBasicInfo']"
                    header="電話番号"
                    style="min-width: 200px"
                  >
                    <template #body="{ data }">
                      {{ formatNational(data.user.phoneNumber) }}
                    </template>
                  </Column>
                  <Column
                    v-if="visibility['showBasicInfo']"
                    :field="(om) => om.user.initPassword"
                    header="初期パスワード"
                    style="min-width: 150px"
                  >
                  </Column>

                  <Column
                    v-if="visibility['showOrganizationInfo']"
                    field="code"
                    header="社員番号"
                    style="min-width: 100px"
                  />
                  <Column
                    v-if="visibility['showOrganizationInfo']"
                    header="所属組織"
                    style="min-width: 150px"
                  >
                    <template #body="{ data }">
                      {{
                        organizations.find((o) => o.id === data.requesterId)
                          ?.name
                      }}
                    </template>
                  </Column>
                  <Column
                    v-if="visibility['showOrganizationInfo']"
                    field="joiningOn"
                    header="入社日"
                    style="min-width: 200px"
                  >
                    <template #body="{ data }">
                      {{ basicFormatter(data.joiningOn, "slashStyleCompact") }}
                    </template>
                  </Column>
                  <Column
                    v-if="visibility['showOrganizationInfo']"
                    field="leavingOn"
                    header="退社日"
                    style="min-width: 200px"
                  >
                    <template #body="{ data }">
                      {{
                        data.leavingOn
                          ? basicFormatter(data.leavingOn, "withoutDateDay")
                          : "-"
                      }}
                    </template>
                  </Column>
                  <Column
                    v-if="visibility['showOrganizationInfo']"
                    field="leavingReason"
                    header="退社理由"
                    style="min-width: 200px"
                  >
                    <template #body="{ data }"
                      >{{ data.leavingReason }}
                    </template>
                  </Column>

                  <Column
                    v-if="visibility['showOrganizationInfo']"
                    header="雇用形態"
                    :field="
                      (om) =>
                        om.labels
                          .filter((l) => l.labelType === 'employment_type')
                          .map((l) => l.labelName)
                          .join()
                    "
                    style="min-width: 100px"
                  >
                  </Column>
                  <Column
                    v-if="visibility['showOrganizationInfo']"
                    header="部署"
                    style="min-width: 100px"
                  >
                  </Column>
                  <Column
                    v-if="visibility['showOrganizationInfo']"
                    header="役職"
                    style="min-width: 100px"
                    :field="
                      (om) =>
                        om.labels
                          .filter((l) => l.labelType === 'position')
                          .map((l) => l.labelName)
                          .join()
                    "
                  >
                  </Column>
                  <Column
                    v-if="visibility['showBankInfo']"
                    field="bankName"
                    header="銀行名"
                    style="min-width: 100px"
                  />
                  <Column
                    v-if="visibility['showBankInfo']"
                    field="bankBranchName"
                    header="支店名"
                    style="min-width: 100px"
                  />
                  <Column
                    v-if="visibility['showBankInfo']"
                    field="bankAccountType"
                    header="口座種別"
                    style="min-width: 100px"
                  >
                    <template #body="{ data }">
                      {{ bankAccountTypeLabel(data.bankAccountType) }}
                    </template>
                  </Column>
                  <Column
                    v-if="visibility['showBankInfo']"
                    field="bankAccountNumber"
                    header="口座番号"
                    style="min-width: 100px"
                  />
                  <Column
                    v-if="visibility['showBankInfo']"
                    field="bankAccountHolder"
                    header="名義"
                    style="min-width: 100px"
                  />

                  <Column
                    v-if="visibility['showPrivateInfo']"
                    :field="(om) => om.user.email"
                    header="メール"
                    style="min-width: 100px"
                  />

                  <Column
                    v-if="visibility['showPrivateInfo']"
                    :field="(om) => om.user.phoneNumber"
                    header="電話番号"
                    style="min-width: 100px"
                  />

                  <Column header="情報更新日" style="min-width: 100px">
                    <template #body="{ data }">
                      {{ basicFormatter(data.updatedAt) }}
                    </template>
                  </Column>
                </DataTable>
              </div>
            </AccordionContent>
          </AccordionPanel>
        </Accordion>
      </template>
    </Card>

    <!--
    <div class="flex w-full justify-end items-center mb-4">
      <BasicButton
        v-if="selectedMemberForBusinessInfo"
        @click="selectedMemberForBusinessInfo = undefined"
      >
        戻る
      </BasicButton>
    </div>
    -->

    <Card
      v-if="isManager && selectedMemberForBusinessInfo"
      class="mb-4 w-full"
      :style="cardWidth"
    >
      <template #content>
        <Accordion value="0">
          <AccordionPanel value="0" class="!border-0">
            <AccordionHeader>
              <div class="flex items-center">
                <span class="me-4"
                  >{{
                    selectedMemberForBusinessInfo.user.lastName +
                    selectedMemberForBusinessInfo.user.firstName
                  }}のアカウント情報</span
                >
              </div>
            </AccordionHeader>
            <AccordionContent>
              <span
                v-if="!selectedMemberForBusinessInfo.businessAccounts.length"
              >
                アカウント情報はありません
              </span>
              <DataTable
                v-else
                :value="selectedMemberForBusinessInfo.businessAccounts"
                striped-rows
                scrollable
                selection-mode="single"
                size="small"
              >
                <Column
                  field="serviceName"
                  header="サービス名"
                  style="min-width: 200px"
                />
                <Column
                  field="createdOn"
                  header="作成日"
                  style="min-width: 200px"
                >
                  <template #body="{ data }">
                    {{ basicFormatter(data.createdOn) }}
                  </template>
                </Column>
                <Column
                  field="disusedOn"
                  header="廃止日"
                  style="min-width: 150px"
                />
                <Column
                  field="email"
                  header="ID(メール)"
                  style="min-width: 150px"
                />
                <Column
                  field="password"
                  header="パスワード"
                  style="min-width: 150px"
                />
                <Column field="lastName" header="姓" style="min-width: 150px" />
                <Column
                  field="firsrtName"
                  header="名"
                  style="min-width: 150px"
                />
                <Column
                  field="birthday"
                  header="生年月日"
                  style="min-width: 150px"
                />
                <Column
                  field="phoneNumber"
                  header="電話番号"
                  style="min-width: 150px"
                />
              </DataTable>
            </AccordionContent>
          </AccordionPanel>
        </Accordion>
      </template>
    </Card>

    <Card
      v-if="isManager && selectedMemberForBusinessInfo"
      class="mb-4"
      :style="cardWidth"
    >
      <template #content>
        <Accordion value="0">
          <AccordionPanel value="0" class="!border-0">
            <AccordionHeader>
              <div class="flex items-center">
                <span class="me-4">
                  {{
                    selectedMemberForBusinessInfo.user.lastName +
                    selectedMemberForBusinessInfo.user.firstName
                  }}の端末情報
                </span>
              </div>
            </AccordionHeader>
            <AccordionContent>
              <span v-if="!selectedMemberForBusinessInfo.businessPhones.length">
                端末情報がありません
              </span>
              <DataTable
                v-else
                :value="selectedMemberForBusinessInfo.businessPhones"
                striped-rows
                scrollable
                selection-mode="single"
                size="small"
              >
                <Column
                  field="remarks"
                  header="端末名"
                  style="min-width: 200px"
                >
                </Column>
                <Column
                  field="startOn"
                  header="貸与日"
                  style="min-width: 200px"
                >
                  <template #body="{ data }">
                    {{ basicFormatter(data.startOn) }}
                  </template>
                </Column>
                <Column
                  field="returnOn"
                  header="返却日"
                  style="min-width: 200px"
                >
                  <template #body="{ data }">
                    {{ basicFormatter(data.returnOn) }}
                  </template>
                </Column>
                <Column
                  field="simStartOn"
                  header="SIM契約日"
                  style="min-width: 200px"
                >
                  <template #body="{ data }">
                    {{ basicFormatter(data.simStartOn) }}
                  </template>
                </Column>
                <Column
                  field="leastOn"
                  header="最低利用日数"
                  style="min-width: 200px"
                >
                  <template #body="{ data }">
                    {{ basicFormatter(data.leastOn) }}
                  </template>
                </Column>

                <Column
                  field="phoneNumber"
                  header="番号"
                  style="min-width: 200px"
                />
                <Column field="simType" header="sim" style="min-width: 200px" />
                <Column
                  field="simCompany"
                  header="会社"
                  style="min-width: 200px"
                />
                <Column
                  field="simContractType"
                  header="契約"
                  style="min-width: 200px"
                />
                <Column
                  field="simContractDetails"
                  header="契約内容"
                  style="min-width: 200px"
                />
                <Column
                  field="deviceType"
                  header="貸与端末"
                  style="min-width: 200px"
                />
                <Column
                  field="deviceCapacity"
                  header="容量"
                  style="min-width: 200px"
                />
                <Column
                  field="deviceColor"
                  header="色"
                  style="min-width: 200px"
                />
                <Column field="imei" header="IMEI" style="min-width: 200px" />
                <Column field="case" header="case" style="min-width: 200px" />
                <Column
                  field="passcode"
                  header="passcode"
                  style="min-width: 200px"
                />
              </DataTable>
            </AccordionContent>
          </AccordionPanel>
        </Accordion>
      </template>
    </Card>

    <SimpleModal v-model:visible="memberActionModal" title="メンバーの操作">
      <div class="flex w-full items-center justify-center">
        <div class="flex flex-col items-center justify-center w-1/2">
          <BasicButton
            @click="memberSelectForBusinessInfo"
            style="width: 100%"
            class="mb-4"
          >
            アカウント/端末情報の表示(↓に表示)
          </BasicButton>
          <BasicButton @click="openMemberConfigModal" style="width: 100%">
            メンバー情報の編集
          </BasicButton>
        </div>
      </div>
    </SimpleModal>

    <SimpleModal v-model:visible="memberConfigModal" title="メンバー情報の編集">
      <template v-if="selectedMember">
        <div class="mb-4">
          {{ selectedMember.user.lastName }}{{ selectedMember.user.firstName }}
        </div>

        <div v-if="requester">
          <ShowFormItemWrapper title="提携元組織">
            {{ requester?.name }}
          </ShowFormItemWrapper>
        </div>

        <OrganizationMemberForm
          v-model:code="form.code"
          v-model:joiningOn="form.joiningOn"
          v-model:leavingOn="form.leavingOn"
          v-model:leavingReason="form.leavingReason"
          v-model:bankName="form.bankName"
          v-model:bankBranchName="form.bankBranchName"
          v-model:bankAccountType="form.bankAccountType"
          v-model:bankAccountNumber="form.bankAccountNumber"
          v-model:bankAccountHolder="form.bankAccountHolder"
          v-model:businessAccounts="form.businessAccounts"
          v-model:businessPhones="form.businessPhones"
          :errors="errors"
          :startValidation="startValidation"
          class="mb-4"
          manager
        />

        <div class="flex justify-end">
          <BasicButton @click="submit">更新</BasicButton>
        </div>
      </template>
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, inject, toRefs, reactive } from "vue";
import {
  useRouterUtil,
  useUser,
  useOrganizationMember,
  useZodScheme,
  useOrganization,
  useMqUtils,
  useDate,
} from "/@/vue/composables";
import {
  ShowFormItemWrapper,
  ShowFormItemTel,
  ShowFormItemAddress,
} from "/@/vue/components/Molecules";
import { BasicButton } from "/@/vue/components/Atom";
import { Form as OrganizationMemberForm } from "/@/vue/components/Organisms/OrganizationMembers";
import { Simple as SimpleModal } from "/@/vue/components/Molecules";
import {
  bankAccountTypeLabel,
  OrganizationMemberCheckScheme,
  OrganizationMemberClient,
  OrganizationMemberForm as OrganizationMemberFormType,
  organizationMemberLabelTypeLabel,
  organizationMemberLabelTypeForms,
} from "/@/types";
import { basicFormatter } from "/@/modules/luxon";
import { formatNational } from "/@/modules/phonenumber";
import Card from "primevue/card";
import Accordion from "primevue/accordion";
import AccordionPanel from "primevue/accordionpanel";
import AccordionHeader from "primevue/accordionheader";
import AccordionContent from "primevue/accordioncontent";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Checkbox from "primevue/checkbox";
import Fieldset from "primevue/fieldset";

const { goto } = useRouterUtil();

//

const { cardWidth } = useMqUtils();

// user

const { getCurrentUser } = useUser();
const { data: currentUser } = getCurrentUser();

// organization

const { getOrganizations } = useOrganization();
const { data: organizations } = getOrganizations();

const selectedOrganizationId = inject("selectedOrganizationId") as Ref<number>;
const selectedOrganization = computed(() => {
  if (!organizations.value) return;
  return organizations.value.find((o) => o.id === selectedOrganizationId.value);
});

const isManager = computed(() => selectedOrganization.value?.isManager);

const { getOrganizationMembers } = useOrganizationMember();
const { data: organizationMembers } = getOrganizationMembers(
  selectedOrganizationId
);

const selectedOrganizationMemberId = ref<number>();
const selectedOrganizationMember = computed(() => {
  if (!organizationMembers.value) return;
  return organizationMembers.value.find(
    (m) => m.id === selectedOrganizationMemberId.value
  );
});

// toggle

const visibility = reactive({
  showBasicInfo: true,
  showOrganizationInfo: false,
  showBankInfo: false,
  showPrivateInfo: false,
});

const checkboxOptions = computed(() => {
  return [
    { label: "基本情報", value: "showBasicInfo" },
    {
      label: "組織情報",
      value: "showOrganizationInfo",
    },
    { label: "銀行情報", value: "showBankInfo" },
    {
      label: "プライベート情報",
      value: "showPrivateInfo",
    },
  ];
});

// member select
const memberActionModal = ref(false);
const memberConfigModal = ref(false);

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<OrganizationMemberFormType>(OrganizationMemberCheckScheme);

function resetForm(om: OrganizationMemberClient) {
  form.id = om.id;
  form.code = om.code || undefined;
  form.joiningOn = om.joiningOn || undefined;
  form.leavingOn = om.leavingOn || undefined;
  form.leavingReason = om.leavingReason || undefined;
  form.bankName = om.bankName || undefined;
  form.bankBranchName = om.bankBranchName || undefined;
  form.bankAccountType = om.bankAccountType || undefined;
  form.bankAccountNumber = om.bankAccountNumber || undefined;
  form.bankAccountHolder = om.bankAccountHolder || undefined;
  form.businessAccounts = om.businessAccounts || undefined;
  form.businessPhones = om.businessPhones || undefined;
}

const selectedMember = ref<OrganizationMemberClient>();
const requester = computed(() => {
  const rid = selectedMember.value?.requesterId;

  if (!rid) return;

  return organizations.value.find((o) => o.id === rid);
});

function memberSelect(e: OrganizationMemberClient) {
  selectedMember.value = e;
  memberActionModal.value = true;
  resetForm(e);
}

function closeMemberActionModal() {
  memberActionModal.value = false;
}

function openMemberConfigModal() {
  memberConfigModal.value = true;
  closeMemberActionModal();
}

function closeMemberConfigModal() {
  memberConfigModal.value = false;
  selectedMember.value = undefined;
}

const selectedMemberForBusinessInfo = ref<OrganizationMemberClient>();

function memberSelectForBusinessInfo() {
  selectedMemberForBusinessInfo.value = selectedMember.value;
  closeMemberActionModal();
}

// api

const { updateOrganizationMember } = useOrganizationMember();

async function submit() {
  try {
    startValidation.value = true;

    if (!selectedMember.value) {
      alert("メンバーが選択されていません。");
      return;
    }

    const args = OrganizationMemberCheckScheme.parse(form);

    if (await updateOrganizationMember(args)) {
      alert("更新しました");
      closeMemberConfigModal();
    } else {
      alert("更新に失敗しました。");
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }
}
</script>

<style scoped></style>
