<template>
  <div>
    <div class="flex items-center justify-center">
      <BasicButton
        v-if="!isWorking"
        label="業務開始"
        icon="pi pi-play"
        class="mb-2"
        text
        with-background
        :disabled="!canStartWorking"
        @click="start"
      />
      <BasicButton
        v-if="isWorking"
        label="業務終了"
        class="mb-2"
        icon="pi pi-stop"
        text
        with-background
        @click="$emit('finish')"
      />
      <!--
      <BasicButton
        v-if="isWorking && !isEntering"
        label="入店(開発中)"
        icon="pi pi-sign-in"
        class="mb-2"
        text
        with-background
        disabled
      />
      <BasicButton
        v-else-if="isWorking && isEntering"
        label="退店(開発中)"
        icon="pi pi-sign-out"
        class="mb-2"
        text
        with-background
      />
      -->

      <BasicButton
        v-if="!isWorking && !isFinishWorking"
        label="欠勤申請"
        class="mb-2"
        icon="pi pi-times"
        text
        with-background
        :disabled="!canStartWorking"
        @click="$emit('absence')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { BasicButton } from "/@/vue/components/Atom";
import { UserClient } from "/@/types";

const props = defineProps<{
  statusAttributes: UserClient["statusAttributes"];
}>();

const emit = defineEmits<{
  (e: "start"): void;
  (e: "finish"): void;
  (e: "absence"): void;
}>();

const canStartWorking = computed(() => {
  return props.statusAttributes.can_start_working;
});

const isWorking = computed(() => {
  return props.statusAttributes.is_working;
});
const isEntering = ref(false);

const isFinishWorking = computed(() => {
  return props.statusAttributes.finish_working;
});

function start() {
  if (isWorking.value) return;

  if (
    isFinishWorking.value &&
    !window.confirm("すでに今日は業務を行っています。業務を再び行いますか？")
  ) {
    return;
  }

  emit("start");
}
</script>

<style scoped>
button.p-button:not(:last-child) {
  margin-right: 1rem;
}
</style>
