<template>
  <div id="user-index">
    <template v-if="homeInfo">
      <div class="flex flex-col w-full">
        <div
          v-if="hasManagedOrganization && isMobile"
          class="flex items-center justify-between mb-4"
        >
          <div>
            <SelectButton
              v-model="showingInfoType"
              :options="infoTypes"
              option-label="label"
              option-value="value"
              style="--p-togglebutton-padding: 0.25rem 0.5rem"
            />
          </div>
        </div>
        <template v-if="!isMobile || showingInfoType === 'today'">
          <div class="flex flex-wrap w-full">
            <div class="md:w-1/3 w-full mb-4 md:pe-3 pe-0">
              <Card class="w-full h-full" :style="indexCardStyle">
                <template #title> 本日の情報 </template>
                <template #content>
                  <div class="flex flex-col justify-center items-center h-full">
                    <CurrentStatus :homeInfo="homeInfo" class="my-4" />
                    <div class="w-full flex items-center justify-center mb-4">
                      <Operations
                        class="w-full max-w-80"
                        @start="start"
                        @finish="finish"
                        @absence="absence"
                        :status-attributes="statusAttributes"
                      />
                    </div>
                    <div>
                      <p class="text-center text-sm text-gray-500">
                        本日の終了のタスクはありません
                      </p>
                    </div>
                  </div>
                </template>
              </Card>
            </div>

            <div class="md:w-1/3 w-full mb-4 md:px-1 px-0">
              <Card class="w-full h-full" :style="indexCardStyle">
                <template #title> </template>
                <template #content>
                  <DataTable :value="weeksArray" size="small" scrollable>
                    <Column style="min-width: 110px" header="日にち">
                      <template #body="{ data }">
                        {{ basicFormatter(data.iso, "noYear") }}
                      </template>
                    </Column>
                    <Column header="内容" style="min-width: 230px">
                      <template #body="{ data }">
                        <span v-if="weekShiftsMap[data.iso]">
                          <Tag
                            :value="
                              shiftTypeLabel(weekShiftsMap[data.iso].shiftType)
                            "
                            class="me-2"
                            :color="
                              weekShiftsMap[data.iso].shiftType === '出勤'
                                ? 'success'
                                : 'danger'
                            "
                            :style="tagWidthStyle"
                          />
                          {{
                            basicFormatter(
                              weekShiftsMap[data.iso].startAt,
                              "onlyTime"
                            )
                          }}
                          ~
                          {{
                            basicFormatter(
                              weekShiftsMap[data.iso].finishAt,
                              "onlyTime"
                            )
                          }}
                        </span>
                        <span v-else>-</span>
                      </template>
                    </Column>
                  </DataTable>
                </template>
              </Card>
            </div>

            <div class="md:w-1/3 w-full mb-4 md:ps-3 ps-0">
              <Card class="w-full h-full" :style="indexCardStyle">
                <template #title> </template>
                <template #content>
                  <div class="flex items-center justify-center">
                    <DateForm
                      inline
                      hide-selected
                      :min-date="calendarMinDate"
                      :max-date="calendarMaxDate"
                      :markers="nearShiftDates"
                      :clear-button-props="{ style: { display: 'none' } }"
                    />
                  </div>
                </template>
              </Card>
            </div>
          </div>

          <div class="w-full flex flex-wrap">
            <div class="md:w-1/2 w-full md:pe-2 pe-0 mb-4">
              <Card class="h-full">
                <template #title> タスク一覧 </template>
                <template #content>
                  <div class="overflow-scroll"></div>
                </template>
              </Card>
            </div>
            <div class="md:w-1/2 w-full md:ps-2 ps-0 mb-4">
              <Card class="h-full">
                <template #title>
                  直近に作成したリクエスト（最新5件）
                </template>
                <template #content>
                  <div class="overflow-scroll">
                    <ApprovalRequestCard
                      v-for="(ar, idx) in recentlyRequests.slice(0, 5)"
                      :key="ar.id"
                      :approval-request="ar"
                      readonly
                      :class="{
                        'mb-4': idx !== recentlyRequests.length - 1,
                      }"
                    />
                  </div>
                </template>
              </Card>
            </div>
          </div>
        </template>
        <template v-if="!isMobile || showingInfoType === 'member'">
          <div
            v-if="statusAttributes.has_managed_organizations"
            class="w-full flex flex-wrap"
          >
            <div class="md:w-1/2 w-full mb-4 md:pe-2 pe-0">
              <Card
                v-if="statusAttributes.has_managed_organizations"
                class="h-full"
              >
                <template #title> メンバーの勤務状況 </template>
                <template #content>
                  <DataTable
                    v-if="membersHasTodayActivities.length"
                    :value="membersHasTodayActivities"
                    scrollable
                    size="small"
                  >
                    <Column style="min-width: 100px">
                      <template #body="{ data }">
                        {{ data.user.lastName }} {{ data.user.firstName }}
                      </template>
                    </Column>
                    <Column header="シフト" style="min-width: 200px">
                      <template #body="{ data }">
                        <div
                          v-if="data.todayShifts.length"
                          class="flex flex-col"
                        >
                          <span v-for="s in data.todayShifts">
                            <Tag
                              :value="shiftTypeLabel(s.shiftType)"
                              class="me-2"
                              :color="
                                s.shiftType === '出勤' ? 'success' : 'danger'
                              "
                              :style="tagWidthStyle"
                            />
                            {{ basicFormatter(s.startAt, "onlyTime") }} ~
                            {{ basicFormatter(s.finishAt, "onlyTime") }}
                          </span>
                        </div>
                        <span v-else>シフトなし</span>
                      </template>
                    </Column>
                    <Column
                      header="業務状況"
                      style="width: 100%; min-width: 250px"
                    >
                      <template #body="{ data }">
                        <div
                          v-if="data.todayAttendances.length"
                          class="flex flex-col"
                        >
                          <span v-for="a in data.todayAttendances">
                            <Tag
                              :value="attendanceTypeLabel(a.attendanceType)"
                              class="me-2"
                              :severity="
                                a.attendanceType === 'working'
                                  ? 'primary'
                                  : 'danger'
                              "
                              :style="tagWidthStyle"
                            />
                            <span v-if="a.attendanceType === 'working'">
                              {{ basicFormatter(a.startAt, "onlyTime") }} ~
                              {{
                                a.finishAt
                                  ? basicFormatter(a.finishAt, "onlyTime")
                                  : "勤務中"
                              }}
                            </span>
                            <span v-else></span>
                          </span>
                        </div>
                        <span v-else>まだ業務を行っていません</span>
                      </template>
                    </Column>
                  </DataTable>
                  <span v-else>本日の勤務情報はありません</span>
                </template>
              </Card>
            </div>
            <div class="md:w-1/2 w-full mb-4 md:ps-2 ps-0">
              <Card class="h-full">
                <template #title> メンバーの明日の勤務予定 </template>
                <template #content>
                  <DataTable
                    v-if="membersHasTomorrowShifts.length"
                    :value="membersHasTomorrowShifts"
                    scrollable
                    size="small"
                  >
                    <Column style="min-width: 100px">
                      <template #body="{ data }">
                        {{ data.user.lastName }} {{ data.user.firstName }}
                      </template>
                    </Column>
                    <Column header="シフト" style="width: 100%">
                      <template #body="{ data }">
                        <div
                          v-if="data.tomorrowShifts.length"
                          class="flex flex-col"
                        >
                          <span v-for="s in data.tomorrowShifts">
                            <Tag
                              :value="shiftTypeLabel(s.shiftType)"
                              class="me-2"
                              :color="
                                s.shiftType === '出勤' ? 'success' : 'danger'
                              "
                              :style="tagWidthStyle"
                            />
                            {{ basicFormatter(s.startAt, "onlyTime") }} ~
                            {{ basicFormatter(s.finishAt, "onlyTime") }}
                          </span>
                        </div>
                        <span v-else>シフトなし</span>
                      </template>
                    </Column>
                  </DataTable>
                  <span v-else>明日の勤務予定はありません</span>
                </template>
              </Card>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { basicFormatter, luxonNow } from "/@/modules/luxon";
import { useUser, useRouterUtil, useMqUtils } from "/@/vue/composables";
import { Operations } from "/@/vue/components/Organisms/Users";
import { CurrentStatus } from "/@/vue/components/Organisms/Attendances";
import { Card as ApprovalRequestCard } from "/@/vue/components/Organisms/ApprovalRequests";
import { DateForm } from "/@/vue/components/Atom/Form";
import { attendanceTypeLabel, shiftTypeLabel } from "/@/types";
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tag from "primevue/tag";
import SelectButton from "primevue/selectbutton";

const { isMobile } = useMqUtils();
const { goto } = useRouterUtil();

// user

const { getCurrentUser, getHome } = useUser();
const { data: user } = getCurrentUser();

const statusAttributes = computed(() => {
  if (!user.value) return {};
  return user.value.statusAttributes;
});

const hasManagedOrganization = computed(() => {
  return statusAttributes.value.has_managed_organizations;
});

const showingInfoType = ref("today");

const infoTypes = [
  { label: "業務", value: "today" },
  { label: "メンバー情報", value: "member" },
];

// api

const { data: homeInfo } = getHome();

const currentAttendance = computed(() => {
  if (!homeInfo.value) return;
  return homeInfo.value.currentAttendance;
});

const today = luxonNow();

function createWeeksArray() {
  let current = today.plus({ days: 1 });
  let m = [];

  for (let i = 0; i < 7; i++) {
    m.push({ iso: current.toFormat("yyyy-MM-dd") });
    current = current.plus({ days: 1 });
  }

  return m;
}

const weeksArray = createWeeksArray();

const weekShiftsMap = computed(() => {
  if (!homeInfo.value) return {};

  let m = {};

  homeInfo.value.weekShifts.forEach((s) => {
    m[s.date] = s;
  });

  return m;
});

const membersHasTodayActivities = computed(() => {
  if (!homeInfo.value) return [];
  return homeInfo.value.membersHasTodayActivities;
});

const membersHasTomorrowShifts = computed(() => {
  if (!homeInfo.value) return [];
  return homeInfo.value.membersHasTomorrowShifts;
});

const calendarMinDate = today.startOf("month");
const calendarMaxDate = today.plus({ month: 1 }).endOf("month");

const nearShiftDates = computed(() => {
  if (!homeInfo.value) return [];
  return homeInfo.value.nearShiftDates;
});

const recentlyRequests = computed(() => {
  // ここ一週間に作成した申請の状況

  if (!homeInfo.value) return [];
  return homeInfo.value.recentlyRequests;
});

// operations

function start() {
  goto({ name: "UsersAttendancesNew" });
}

function finish() {
  if (!currentAttendance.value) return;

  goto({
    name: "UsersAttendancesEdit",
    params: { id: currentAttendance.value.id },
  });
}

function absence() {
  goto({
    name: "UsersAttendancesAbsence",
  });
}

// style

const tagWidthStyle = "width: 60px";
const indexCardStyle = {
  maxHeight: "390px",
  overflow: "scroll",
};
</script>

<style scoped lang="scss"></style>
