<template>
  <div>
    <div class="mb-4">
      <FormItemText
        v-model="labelName"
        :errors="errors"
        label="名前 ※50文字以内"
        form-id="labelName"
        form-type="text"
      />
    </div>
    <div class="mb-4">
      <FormItemWrapper
        form-id="labelType"
        :errors="errors"
        label="ラベルの種類"
      >
        <Select
          v-model="labelType"
          :options="organizationMemberLabelTypeForms"
          :option-label="organizationMemberLabelTypeLabel"
          filter
          no-empty-label="ラベルの種類を選択してください。"
          label="ラベルの種類"
          form-id="labelType"
          :invalid="!!errors.labelType"
        />
      </FormItemWrapper>
    </div>

    <div class="mb-4">
      <FormItemWrapper form-id="parentId" :errors="errors" label="ラベルの親">
        <Select
          v-model="parentId"
          :options="parentCandidates"
          option-label="labelName"
          option-value="id"
          filter
          no-empty-label="ラベルの親を選択してください。"
          form-id="parentId"
          :invalid="!!errors.parentId"
        />
      </FormItemWrapper>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  FormItemText,
  FormItemWrapper,
} from "/@/vue/components/Molecules/Form";
import {
  OrganizationMemberLabelOption,
  organizationMemberLabelTypeForms,
  organizationMemberLabelTypeLabel,
  ZodFormattedErrors,
} from "/@/types";
import Select from "primevue/select";
import { computed } from "vue";

const labelName = defineModel<string | undefined>("labelName");
const labelType = defineModel<OrganizationMemberLabelOption["labelType"]>(
  "labelType",
  {
    default: "employment_type",
  }
);
const parentId = defineModel<number | undefined>("parentId");

const props = defineProps<{
  errors: ZodFormattedErrors;
  parents: OrganizationMemberLabelOption[];
}>();

const parentCandidates = computed(() => {
  return props.parents.filter((p) => p.labelType === labelType.value);
});
</script>

<style scoped></style>
