<template>
  <div>
    <form v-if="showForm" @submit.prevent="signup">
      <Card v-if="!selectedOperation">
        <template #content>
          <div class="flex items-center">
            <BasicButton
              class="me-4"
              variant="secondary"
              @click="selectedOperation = 'invite'"
            >
              招待リンクの作成
            </BasicButton>
            <BasicButton
              class="me-4"
              variant="secondary"
              @click="selectedOperation = 'create'"
            >
              情報の入力
            </BasicButton>
          </div>
        </template>
      </Card>
      <template v-else>
        <template v-if="!confirming">
          <Card class="mb-4">
            <template #content>
              <div v-if="joiningOrganization">
                <Tag value="参加する組織" class="me-4" />
                <span>{{ joiningOrganization?.name }}</span>
              </div>
              <div v-else-if="targetOrganizations.length">
                <Tag value="参加する組織" class="me-4" />
                <SelectObject
                  v-model="selectedOrganizationId"
                  :options="targetOrganizations"
                  option-label="name"
                  key-name="id"
                  value-name="name"
                  class="w-full"
                />
              </div>
              <div
                v-if="
                  (requesterOrganization &&
                    joiningOrganization &&
                    requesterOrganization.id !== joiningOrganization.id) ||
                  (requesterOrganization && !joiningOrganization)
                "
                class="mt-4"
              >
                <Tag value="所属組織" class="me-4" />
                <span>{{ requesterOrganization?.name }}</span>
              </div>
              <div
                v-else-if="
                  !requesterOrganization && candidateOrganizations.length
                "
                class="flex items-center mt-4"
              >
                <Tag value="所属組織" class="text-nowrap me-4" />
                <SelectObject
                  v-model="selectedRequesterId"
                  :options="candidateOrganizations"
                  option-label="name"
                  key-name="id"
                  value-name="name"
                  class="w-full"
                />
              </div>

              <template
                v-if="
                  selectedOrganizationId &&
                  selectedRequesterId &&
                  selectedOperation === 'invite'
                "
              >
                <div class="flex flex-col mt-4">
                  <div
                    v-for="(emap, idx) in invitingEmails"
                    class="flex flex-row mb-4"
                  >
                    <FormItemEmail
                      v-model="emap.email"
                      :form-id="'emailCheck'"
                      label="招待するメールアドレス"
                      :errors="errors"
                      class="me-4"
                      style="min-width: 280px"
                    />
                    <BasicButton
                      class="mt-4"
                      icon="pi pi-trash"
                      variant="danger"
                      @click="removeEmails(idx)"
                    />
                  </div>

                  <BasicButton
                    @click="invitingEmails.push({ email: '' })"
                    icon="pi pi-plus"
                    class="mt-4"
                  />
                </div>

                <div class="flex flex-wrap items-center mt-4">
                  <BasicButton
                    class="me-1 text-nowrap"
                    variant="secondary"
                    icon="pi pi-times"
                    style="min-width: 100px"
                    @click="selectedOperation = undefined"
                  >
                    キャンセル
                  </BasicButton>
                  <BasicButton @click="generateInviteUrl" class="me-4">
                    招待URLの発行
                  </BasicButton>
                  <div
                    v-if="inviteUrl"
                    class="flex flex-wrap items-center md:mt-0 mt-4"
                  >
                    <Tag value="招待URL" class="md:me-4" />
                    <span>{{ inviteUrl }}</span>
                    <Tag class="md:ms-4">クリップボードにコピーしました</Tag>
                  </div>
                </div>
              </template>
            </template>
          </Card>

          <template
            v-if="
              (selectedOrganizationId || joiningOrganizationId) &&
              selectedOperation === 'create'
            "
          >
            <UserForm
              v-model:last-name="signUpForm.lastName"
              v-model:first-name="signUpForm.firstName"
              v-model:last-name-kana="signUpForm.lastNameKana"
              v-model:first-name-kana="signUpForm.firstNameKana"
              v-model:birthday="signUpForm.birthday"
              v-model:email="signUpForm.email"
              v-model:email-check="emailCheck"
              v-model:phone-number="signUpForm.phoneNumber"
              v-model:postalCode="signUpForm.postalCode"
              v-model:address-level1="signUpForm.addressLevel1"
              v-model:address-level2="signUpForm.addressLevel2"
              v-model:address-line1="signUpForm.addressLine1"
              v-model:address-line2="signUpForm.addressLine2"
              v-model:nearest-station="signUpForm.nearestStation"
              :errors="errors"
              class="mb-4"
            />

            <Card v-if="withPassword">
              <template #content>
                <FormItemPassword
                  v-model="signUpForm.password"
                  :errors="errors"
                  label="パスワード(8~32文字の半角英字で小文字・大文字・数字をそれぞれ一つ以上含む)"
                  form-id="password"
                  autocomplete="new-password"
                  class="mb-4"
                  style="max-width: 300px"
                />
                <FormItemPassword
                  v-model="signUpForm.passwordConfirmation"
                  :errors="errors"
                  label="パスワード(確認)"
                  form-id="passwordConfirmation"
                  form-type="password"
                  autocomplete="new-password"
                  style="max-width: 300px"
                />
              </template>
            </Card>

            <div class="mt-4 flex justify-end">
              <BasicButton
                class="me-1"
                variant="secondary"
                icon="pi pi-times"
                text
                style="min-width: 100px"
                @click="backto"
              >
                キャンセル
              </BasicButton>
              <BasicButton class="ms-1" @click="confirm"> 確認 </BasicButton>
            </div>
          </template>
        </template>
        <Card v-else>
          <template #content>
            <UserShow :user="signUpForm" />

            <div class="mt-4 flex justify-end">
              <BasicButton class="me-1" outlined @click="confirming = false">
                修正
              </BasicButton>
              <BasicButton
                class="ms-1"
                button-type="submit"
                :disabled="loading"
                @click="signup"
              >
                アカウントの作成
              </BasicButton>
            </div>
          </template>
        </Card>
      </template>
    </form>
    <span v-else>
      <p>無効なURLです。組織からの招待リンクが必要です</p>
    </span>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from "vue";
import { scrollToTop } from "/@/modules/ui";
import { getAddress } from "/@/modules/address";
import { gPhoneNumber, gKana, gPassword, gPostcode } from "/@/modules/groomer";
import {
  useZodScheme,
  useRouterUtil,
  useUser,
  useOrganization,
} from "/@/vue/composables";
import { BasicButton, SelectObject } from "/@/vue/components/Atom";
import { FormItemPassword, FormItemEmail } from "/@/vue/components/Molecules";
import {
  Form as UserForm,
  Show as UserShow,
} from "/@/vue/components/Organisms/Users";
import { User, SignUpScheme, SignUpForm, OrganizationClient } from "/@/types";
import { errorHandle } from "/@/modules/error";
import Tag from "primevue/tag";
import Card from "primevue/card";
import { zen2han } from "/@/modules/string";

const { backto, currentRouteQuery, goto } = useRouterUtil();

// user

const { getCurrentUser } = useUser();
const { data: currentUser } = getCurrentUser();

// operation

const selectedOperation = ref<"invite" | "create">();

onMounted(() => {
  if (!currentUser.value) {
    selectedOperation.value = "create";
  }
});

// organization

const { getOrganizations } = useOrganization();
const { data: candidateOrganizationsData } = getOrganizations();

const candidateOrganizations = computed(() => {
  return candidateOrganizationsData.value || [];
});

const organizations = computed(() => {
  if (!currentUser.value) return [];
  return currentUser.value.organizations;
});
const managedOrganizations = computed(() => {
  if (!organizations.value) return [];
  return organizations.value.filter((o) => o.isManager);
});

const targetOrganizations = computed<OrganizationClient[]>(() => {
  if (currentUser.value) {
    return managedOrganizations.value;
  } else {
    return candidateOrganizations.value || [];
  }
});

const selectedOrganizationId = ref<number>();

const joiningOrganizationId = computed(() => {
  const idString = currentRouteQuery.value?.organizationId;

  if (!idString) return;
  return parseInt(idString);
});

const joiningOrganization = computed(() => {
  if (!joiningOrganizationId.value && !selectedOrganizationId.value) return;

  const oid = joiningOrganizationId.value || selectedOrganizationId.value;

  return targetOrganizations.value.find((o) => o.id === oid);
});

const requesterOrganizationId = computed(() => {
  const idString = currentRouteQuery.value?.requesterId;

  if (!idString) return;
  return parseInt(idString);
});

const selectedRequesterId = ref<number>();

const requesterOrganization = computed(() => {
  if (!requesterOrganizationId.value) return;

  return targetOrganizations.value.find(
    (o) => o.id === requesterOrganizationId.value
  );
});

const showForm = computed(() => {
  return (
    !!currentUser.value ||
    (!!joiningOrganization.value && !!requesterOrganization.value)
  );
});

// invite

const { addNewMember, sendInvitations } = useOrganization();

const inviteUrl = ref();

const invitingEmails = ref([
  {
    email: "",
  },
]);

function removeEmails(idx: number) {
  invitingEmails.value.splice(idx, 1);
}

async function invitation() {
  if (!selectedOrganizationId.value || !selectedRequesterId.value) {
    throw new Error("組織を選択して下さい。");
  }

  if (!invitingEmails.value.length) {
    throw new Error("招待するメールアドレスを入力して下さい。");
  }

  const emails = invitingEmails.value;
  // console.log("emails", invitingEmails.value, emails);

  if (
    await sendInvitations(
      selectedOrganizationId.value,
      emails,
      selectedRequesterId.value
    )
  ) {
    alert("招待メールを送信しました。");
  } else {
    alert("招待メールの送信に失敗しました。");
  }
}

async function generateInviteUrl() {
  if (!selectedOrganizationId.value || !selectedRequesterId.value) return;

  invitation();

  inviteUrl.value = `${location.origin}/user/new?organizationId=${selectedOrganizationId.value}&requesterId=${selectedRequesterId.value}`;
  navigator.clipboard.writeText(inviteUrl.value);
}

// form

const withPassword = computed(() => {
  return !!joiningOrganizationId.value;
});

onMounted(() => {
  /*
  signUpForm.lastName = "試験";
  signUpForm.lastNameKana = "シケン";
  signUpForm.firstName = "ついか";
  signUpForm.firstNameKana = "ツイカ";
  signUpForm.birthday = "1990-01-17";
  signUpForm.email = "s1200191@gmail.com";
  signUpForm.phoneNumber = "09012345678";
  signUpForm.postalCode = "9200966";
  signUpForm.addressLevel1 = "石川県";
  signUpForm.addressLevel2 = "金沢市";
  signUpForm.addressLine1 = "片町1-1建物〇〇102";
  signUpForm.nearestStation = "金沢駅";
  emailCheck.value = "s1200191@gmail.com";
  */
});

const { useFormAndErrors } = useZodScheme();
const {
  form: signUpForm,
  errors,
  startValidation,
} = useFormAndErrors<SignUpForm>(SignUpScheme, {
  birthday: "1990-01-01",
});

const emailCheck = ref("");

startValidation.value = true;

watch(
  () => signUpForm.postalCode,
  async (p, old) => {
    if (p !== old) {
      const res = await getAddress(p);

      if (res) {
        const { pref, city, town } = res;
        signUpForm.addressLevel1 = pref;
        signUpForm.addressLevel2 = city;
        signUpForm.addressLine1 = town;
      }
    }
  }
);

const formKey = ref(0);

watch(signUpForm, (f) => {
  // form を watch する場合は、まとめて変更した時の処理を書けるが、
  // 無駄な比較処理が増えるのと過去の値へのアクセスができない問題がある。
  // そのため個別のプロパティでの変更があった時のような処理が書けない。

  // console.log("watch signUpForm", f);

  if (f.firstName && f.firstName.length > 50) {
    signUpForm.firstName = f.firstName.slice(0, 50);
  }

  if (f.lastName && f.lastName.length > 50) {
    signUpForm.lastName = f.lastName.slice(0, 50);
  }

  if (f.firstNameKana) {
    const kana = gKana(f.firstNameKana);
    signUpForm.firstNameKana = kana.slice(0, 50);
  }

  if (f.lastNameKana) {
    const kana = gKana(f.lastNameKana);
    signUpForm.lastNameKana = kana.slice(0, 50);
  }

  if (f.phoneNumber) {
    signUpForm.phoneNumber = gPhoneNumber(f.phoneNumber);
  }

  if (f.postalCode) {
    signUpForm.postalCode = gPostcode(f.postalCode);
  }

  if (f.addressLine1) {
    signUpForm.addressLine1 = zen2han(f.addressLine1.slice(0, 100));
  }

  if (f.addressLine2) {
    signUpForm.addressLine2 = zen2han(f.addressLine2.slice(0, 100));
  }

  if (f.password) {
    signUpForm.password = gPassword(f.password);
  }
});

// confirm

const confirming = ref(false);

function confirm() {
  try {
    if (signUpForm.email !== emailCheck.value) {
      throw new Error("メールアドレスが一致しません。");
    }

    if (!joiningOrganizationId.value) {
      signUpForm.password = "Password123";
      signUpForm.passwordConfirmation = "Password123";
    }

    SignUpScheme.parse(signUpForm);
    scrollToTop();
    confirming.value = true;
  } catch (e) {
    alert("入力内容に問題があります。赤枠の項目を確認して下さい。");
    errorHandle(e);
  }
}

// submit

const loading = ref(false);

async function signup() {
  try {
    loading.value = true;

    const jo = joiningOrganization.value;
    if (!jo?.id) return;

    if (requesterOrganizationId.value || selectedRequesterId.value) {
      signUpForm.requesterId =
        requesterOrganizationId.value || selectedRequesterId.value;
    }

    if (signUpForm.email !== emailCheck.value) {
      throw new Error("メールアドレスが一致しません。");
    }

    const user = SignUpScheme.parse(signUpForm);

    if (await addNewMember(jo.id, user)) {
      if (currentUser.value) {
        goto({ name: "UsersOrganizationsIndex", params: { id: jo.id } });
      } else {
        goto({ name: "UsersSuccess", query: { fromUsersNew: "true" } });
      }
    }
  } catch (e) {
    alert("入力内容に問題があります。赤枠の項目を確認して下さい。");
    errorHandle(e);
  } finally {
    loading.value = false;
  }
}
</script>

<style scoped></style>
