<template>
  <div>
    <div class="flex items-center mb-4">
      <SelectDate
        v-model:year="selectedDate.year"
        v-model:month="selectedDate.month"
        :existsDates="existsDates"
      />
    </div>

    <div class="mb-4">
      <template v-if="isManager">
        <Card
          class="w-full mb-4"
          :style="{
            ...cardWidth,
          }"
          :class="modeColorMap[currentMode]"
        >
          <template #content>
            <div class="flex items-center justify-between">
              <vue-draggable
                v-model="candidateShifts"
                group="shifts"
                @start="() => {}"
                @end="
                  {
                  }
                "
                item-key="shitType"
                class="flex flex-wrap"
                @update:model-value="resetShifts"
              >
                <div v-for="element in candidateShifts" :key="element.id">
                  <ShiftBadge
                    :shift="element"
                    is-base-time
                    display-mode="compact"
                    @click="changeMode(element)"
                  />
                </div>
              </vue-draggable>

              <div class="flex items-center">
                <i class="pi pi-eye me-2"></i>
                <SelectButton
                  name="font-size"
                  v-model="displayMode"
                  :options="displayModes"
                  option-label="label"
                  option-value="value"
                  size="small"
                  style="--p-togglebutton-padding: 0.25rem 0.5rem"
                />
              </div>
            </div>
          </template>
        </Card>

        <Card
          class="mb-4"
          :style="{
            ...cardWidth,
          }"
          :class="modeColorMap[currentMode]"
        >
          <template #content>
            <div class="w-fit overflow-x-scroll relative" style="width: 100%">
              <table
                class="max-h-5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
              >
                <thead
                  class="relative text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
                >
                  <tr>
                    <th
                      class="w-3 text-center sticky left-0 top-0 bg-gray-50 dark:bg-gray-700"
                      style="min-width: 70px"
                    >
                      メンバー
                    </th>
                    <th
                      v-for="d in dates"
                      scope="col"
                      class="px-5 py-3 text-center"
                      :class="{
                        'text-red-500': d.weekday === 1,
                        'text-blue-500': d.weekday === 7,
                      }"
                    >
                      {{ d.luxonDate.toFormat("d") }}
                    </th>
                    <th
                      class="px-5 text-nowrap sticky left-0 top-0 right-0 bg-gray-50 dark:bg-gray-700"
                    >
                      稼働数
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="om in organizationMembers"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-center"
                    style="height: 39px"
                    :key="om.id"
                  >
                    <th
                      style="min-height: 39px"
                      class="sticky left-0 top-0 bg-white dark:bg-gray-800"
                    >
                      <span
                        class="text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {{ om.user.lastName }}
                      </span>
                    </th>
                    <LMDate
                      v-for="d in dates"
                      :om="om"
                      :date="d"
                      :shifts="
                        organizationMemberShiftInfos[om.id].filter(
                          (s) => s.date === d.dateKey
                        )
                      "
                      :key="d.dateKey"
                      :base-time="baseTime"
                      :display-mode="displayMode"
                      @open-modal="openModal"
                      @update-shift="dropUpdateShift"
                      @click="dropUpdateShift(om, d, [currentSelectedShift])"
                    />
                    <td
                      class="bg-white dark:bg-gray-800 sticky left-0 top-0 right-0"
                    >
                      {{
                        organizationMemberShiftInfos[om.id].filter(
                          (s) => !s.isHoliday
                        ).length
                      }}/{{ dates.length - 8 }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
        </Card>
      </template>
      <Card v-else>
        <template #content> 権限がありません </template>
      </Card>
    </div>

    <SimpleModal v-model:visible="modalVisible" title="シフト詳細">
      <div class="flex justify-end w-full">
        <BasicButton
          icon="pi pi-trash"
          variant="danger"
          @click="removeShift"
        ></BasicButton>
      </div>

      <ShiftForm
        v-model:id="form.id"
        v-model:shift-type="form.shiftType"
        v-model:start-at="form.startAt"
        v-model:finish-at="form.finishAt"
        v-model:rest-start-at="form.restStartAt"
        v-model:rest-finish-at="form.restFinishAt"
        v-model:content-type="form.contentType"
        v-model:content-details="form.contentDetails"
        :errors="errors"
      />

      <div class="flex flex-row justify-end">
        <BasicButton
          label="キャンセル"
          variant="secondary"
          icon="pi pi-times"
          text
          class="me-2"
          @click="closeModal"
        ></BasicButton>
        <BasicButton
          label="更新"
          button-type="submit"
          variant="primary"
          @click="updateShift"
          class="me-2"
          :disabled="!existsAnyChanges"
        ></BasicButton>
      </div>
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { VueDraggable } from "vue-draggable-plus";
// import { Container, Draggable } from "vue-smooth-dnd";
import { ref, computed, reactive, inject, Ref, onMounted } from "vue";
import { luxonNow, existsDates } from "/@/modules/luxon";
import { createDateForCalendars } from "/@/modules/calendar";
import {
  useRouterUtil,
  useUser,
  useOrganizationMember,
  useShift,
  useZodScheme,
  useDate,
  useMqUtils,
} from "/@/vue/composables";
import { BasicButton, SelectDate, SelectObject } from "/@/vue/components/Atom";
import { Simple as SimpleModal } from "/@/vue/components/Molecules";
import {
  LMDate,
  Form as ShiftForm,
  Badge as ShiftBadge,
} from "/@/vue/components/Organisms/Shifts";
import {
  DateForCalendar,
  DateMap,
  OrganizationClient,
  Shift,
  ShiftCheckScheme,
  ShiftClient,
  ShiftFormType,
  baseShifts,
  shiftTypeLabel,
} from "/@/types";
import { OrganizationMemberClient } from "/@/types/organizationMember";
import Card from "primevue/card";
import SelectButton from "primevue/selectbutton";

const { cardWidth } = useMqUtils();

// display mode

type DisplayMode = "normal" | "compact";
const displayMode = ref("compact");

const displayModes: { label: string; value: DisplayMode }[] = [
  { label: "詳細", value: "normal" },
  { label: "コンパクト", value: "compact" },
];

// date

const today = luxonNow();

const selectedDate = reactive<DateMap>({
  year: today.year,
  month: today.month,
});

const dates = computed(() => {
  return createDateForCalendars(selectedDate);
});

// organization

const selectedOrganizationId = inject("selectedOrganizationId");
const selectedOrganization = inject<Ref<OrganizationClient>>(
  "selectedOrganization"
);

const isManager = computed(() => {
  if (!selectedOrganization.value) return false;
  return selectedOrganization.value.isManager;
});

const { getOrganizationMembers, updateOrganizationMember } =
  useOrganizationMember();
const { data: organizationMembers, mutate: getOrganizationMembersMutate } =
  getOrganizationMembers(
    selectedOrganizationId,
    {
      withShifts: true.toString(),
      withLaborInformations: true.toString(),
    },
    selectedDate
  );

const organizationMemberShiftInfos = computed(() => {
  if (!organizationMembers.value) return {};

  let m: Record<number, ShiftClient[]> = {};

  organizationMembers.value.forEach((om) => {
    m[om.id] = om.shifts;
  });

  return m;
});

// base

const baseTime = computed<{ startAt: string; finishAt: string }>(() => {
  const defaultStartAt = "2000-01-01T09:00:00.000Z+09:00";
  const defaultFinishAt = "2000-01-01T18:00:00.000Z+09:00";

  if (
    !selectedOrganization.value?.baseStartAt ||
    !selectedOrganization.value?.baseFinishAt
  )
    return {
      startAt: defaultStartAt,
      finishAt: defaultFinishAt,
    };

  const startAt = selectedOrganization.value.baseStartAt;
  const finishAt = selectedOrganization.value.baseFinishAt;

  return {
    startAt: startAt || defaultStartAt,
    finishAt: finishAt || defaultFinishAt,
  };
});

// mode

const currentSelectedShift = ref<ShiftClient | undefined>(undefined);
const currentMode = computed<ShiftClient["shiftType"]>(() => {
  if (!currentSelectedShift.value) return undefined;

  return currentSelectedShift.value.shiftType;
});

const modeColorMap: Map<ShiftClient["shiftType"], string> = {
  fulltime: "!border-green-500",
  paid_holiday: "!border-blue-500",
  special_holiday: "!border-blue-500",
  half_holiday: "!border-red-900",
  holiday: "!border-red-500",
};

function changeMode(shift: ShiftClient) {
  currentSelectedShift.value = shift;
}

// shift

const candidateShifts = ref<Shift[]>(baseShifts);
function resetShifts() {
  candidateShifts.value = baseShifts;
}

async function dropUpdateShift(
  om: OrganizationMemberClient,
  date: DateForCalendar,
  event: any
) {
  if (!event) return;

  if (
    organizationMemberShiftInfos.value[om.id].filter(
      (s) => s.date === date.dateKey
    ).length
  ) {
    return;
  }

  const targetShift = event.at(-1);

  if (!targetShift) return;

  let newShift;
  let newShifts = [];

  const targetDateShifts = om.shifts.filter((s) => s.date === date.dateKey);

  if (targetDateShifts.length) {
    alert("すでにシフトが登録されています");

    return;
  }

  if (!targetShift.organizationMemberId) {
    // from candidate
    newShift = {
      ...targetShift,
      organizationMemberId: om.id,
      date: date.dateKey,
    };
    newShifts = [...targetDateShifts, newShift];
  } else if (targetShift.organizationMemberId !== om.id) {
    newShift = {
      ...targetShift,
      id: undefined,
      organizationMemberId: om.id,
      date: date.dateKey,
    };
    newShifts = [
      ...targetDateShifts,
      newShift,
      {
        ...targetShift,
        date: date.dateKey,
        _destory: true,
      },
    ];
  } else {
    newShift = {
      ...targetShift,
      date: date.dateKey,
    };
    newShifts = [
      ...om.shifts.filter(
        (s) => s.date !== date.dateKey && s.id !== newShift.id
      ),
      newShift,
    ];
  }

  await updateOrganizationMember({
    id: om.id,
    shifts: newShifts,
  });

  getOrganizationMembersMutate();
}

const { destroyShift, updateShift: patchShift } = useShift();

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<ShiftFormType>(ShiftCheckScheme);

startValidation.value = true;

const lastTargetShift = ref<ShiftClient>();

function resetForm(shift?: ShiftClient) {
  if (shift) {
    lastTargetShift.value = shift;
  }

  form.id = shift?.id || undefined;
  form.organizationMemberId = shift?.organizationMemberId || undefined;
  form.date = shift?.date || undefined;
  form.shiftType = shift?.shiftType || "fulltime";
  form.contentType = shift?.contentType || "office";
  form.startAt = shift?.startAt || "09:00";
  form.finishAt = shift?.finishAt || "18:00";
  form.restStartAt = shift?.restStartAt || "12:00";
  form.restFinishAt = shift?.restFinishAt || "13:00";
  form.contentType = shift?.contentType || "office";
  form.contentDetails = shift?.contentDetails || "";
}

const existsAnyChanges = computed(() => {
  if (!lastTargetShift.value) return false;

  console.log("existsAnyChanges", form, lastTargetShift.value);

  return (
    form.shiftType !== lastTargetShift.value.shiftType ||
    form.startAt !== lastTargetShift.value.startAt ||
    form.finishAt !== lastTargetShift.value.finishAt ||
    form.restStartAt !== lastTargetShift.value.restStartAt ||
    form.restFinishAt !== lastTargetShift.value.restFinishAt ||
    form.contentType !== lastTargetShift.value.contentType ||
    form.contentDetails !== lastTargetShift.value.contentDetails
  );
});

async function updateShift() {
  try {
    const args = ShiftCheckScheme.parse(form);

    if (!form.id) return;

    if (await patchShift(form.id, args)) {
      getOrganizationMembersMutate();
      selectedShiftId.value = undefined;
      closeModal();
    } else {
      console.error("updateShift failed");
    }
  } catch (e) {
    console.error(e);
  }
}

async function removeShift() {
  if (!selectedShiftId.value) return;

  if (await destroyShift(selectedShiftId.value)) {
    getOrganizationMembersMutate();
    selectedShiftId.value = undefined;
    closeModal();
  } else {
    console.error("removeShift failed");
  }
}

// modal

const modalVisible = ref(false);
const selectedShiftId = ref<number>();
const selectedShift = computed(() => {
  if (!organizationMembers.value) return;

  return organizationMembers.value
    .map((om) => om.shifts)
    .flat()
    .find((s) => s.id === selectedShiftId.value);
});

function openModal(om: OrganizationMemberClient, d: DateForCalendar) {
  const targetShift = om.shifts.find((s) => s.date === d.dateKey);
  if (!targetShift) return;
  selectedShiftId.value = targetShift.id;
  resetForm(targetShift);

  modalVisible.value = true;
}

function closeModal() {
  modalVisible.value = false;
  lastTargetShift.value = undefined;
}
</script>

<style scoped></style>
